export default theme => ({
  toolbarLogo: {
    width: theme.spacing(6) + 1,
    height: theme.spacing(6) + 1,
    marginLeft: '-10px',
  },
  toolbarLogoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});
