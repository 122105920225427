/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'modules/Core/reducers/selectors';
import { addFav, setProfile, UserProfileDto, removeFav } from 'modules/UserProfile';
import useServer from './useServer';

export default () => {
  const server = useServer();
  const dispatch = useDispatch();

  const p = useSelector((s: any) => s.userProfile);
  const userId = useSelector(getUserId);

  const onAdd = React.useCallback(
    reportId => {
      dispatch(addFav(reportId));
      server
        .postAsync<UserProfileDto>({
          reportId,
        })
        .then(
          r => dispatch(setProfile(r.data)),
          () => dispatch(removeFav(reportId))
        );
    },
    [dispatch, server]
  );

  const onRemove = React.useCallback(
    reportId => {
      dispatch(removeFav(reportId));
      server
        .deleteAsync<UserProfileDto>(userId, {
          reportId,
        })
        .then(
          r => dispatch(setProfile(r.data)),
          () => dispatch(addFav(reportId))
        );
    },
    [dispatch, server, userId]
  );

  const isFav = React.useCallback(
    id => {
      return p.favourites?.includes(id);
    },
    [p.favourites]
  );

  return React.useMemo(() => ({ onAdd, isFav, onRemove, favourites: p.favourites }), [
    onAdd,
    isFav,
    onRemove,
    p.favourites,
  ]);
};
