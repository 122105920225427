/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TimeAgo from 'react-timeago';
import startCase from 'lodash/startCase';
import isString from 'lodash/isString';
import get from 'lodash/get';
import { Fade, Grid } from '@material-ui/core';
import { ContentHeader } from '@ldx-dmp/Common';
import MaterialTable from '@ldx-dmp/MaterialTable';
import { Column } from 'material-table';
import { formatShortDateTime } from '@ldx-dmp/Common/utilities';
import useMaterialServer from './useMaterialServer';
import useServer from './useServer';

type LogEntryDto = {
  id: string;
  streamId: string;
  timestamp: string;
  name: string;
  user: {
    displayName: string;
  };
  data: Record<string, unknown>;
};

function Activity() {
  const server = useServer();

  const initialCols: Column<LogEntryDto>[] = [
    {
      title: 'Timestamp',
      field: 'timestamp',
      grouping: false,
      filtering: false,
      render: x => <TimeAgo date={x.timestamp} title={formatShortDateTime(x.timestamp)} />,
    },
    {
      title: 'User',
      field: 'user.displayName',
      render: (x, type) => {
        if (type === 'group') return x.toString();

        return get(x, 'user.displayName');
      },
    },
    {
      title: 'Action',
      field: 'name',
      render: (x, type) => {
        if (type === 'group') return startCase(x.toString());

        return startCase(x.name);
      },
      customFilterAndSearch: (trimmedSearchText, row) => {
        if (!trimmedSearchText?.length) return true;

        const filter = (v, s) =>
          v.toString().toUpperCase().includes(s?.replace(' ', '')?.toUpperCase());

        if (isString(trimmedSearchText)) {
          const { name: value } = row;
          return filter(value, trimmedSearchText);
        }

        return (trimmedSearchText as []).find(i => filter(row.name, i));
      },
    },
  ];

  const {
    list: { totalCount },
    columns,
    getDataTable,
    isLoading,
    isReloading,
    options: otherOpts,
  } = useMaterialServer(
    server,
    initialCols,
    (cols: Column<LogEntryDto>[], list, filters) => {
      return cols.map(col => ({
        ...col,
        defaultFilter: filters[col.field || ''],
        lookup: (list.aggregations || {})[col.field || '']?.reduce(
          (r, c) => ({
            ...r,
            [c.name]: `${c.name} (${c.count})`,
          }),
          {}
        ),
      }));
    },
    'timestamp desc',
    false
  );

  const options = {
    ...otherOpts,
    grouping: true,
    filtering: true,
    pageSizeOptions: [10, 25, 50, 100, 150],
    emptyRowsWhenPaging: false,
  };

  return (
    <>
      <Fade in>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentHeader title="Activity" />
            </Grid>
            <Grid item xs={12}>
              <MaterialTable
                title=""
                data={getDataTable}
                totalCount={totalCount}
                columns={columns}
                options={options}
                isLoading={isLoading || isReloading}
                detailPanel={({ streamId, data: { user, timestamp, ...rest } }) => (
                  <pre>{JSON.stringify({ ...rest, streamId }, null, 2)}</pre>
                )}
              />
            </Grid>
          </Grid>
        </>
      </Fade>
    </>
  );
}

export default Activity;
