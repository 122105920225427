import appLoadInterceptors from './appLoadInterceptors';

const createInterceptorMiddleware = interceptors => store => next => action => {
  const matchesActionType = (interceptor, type) => {
    if (Array.isArray(interceptor.type)) return interceptor.type.includes(type);
    return interceptor.type === type;
  };

  Promise.all(
    interceptors
      .filter(interceptor => matchesActionType(interceptor, action.type))
      .map(interceptor => {
        const result = interceptor.handler(action, store.dispatch, store.getState);
        return result instanceof Promise ? result : Promise.resolve(result);
      })
  )
    .then(afterDispatchHandlers => {
      next(action);
      afterDispatchHandlers.forEach(
        handler => typeof handler === 'function' && handler(action, store.dispatch, store.getState)
      );
    })
    // TODO: Add error handling
    // eslint-disable-next-line no-console
    .catch(e => console.error(e));
};

export default createInterceptorMiddleware([...appLoadInterceptors]);
