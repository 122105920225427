import { Reducer } from 'redux';
import { NavigationActionTypes, AppActionTypes, abpConfig } from '../actions';

import { AppState } from '../types';

const initialState: AppState = {
  user: {
    profile: undefined,
    permissions: undefined,
  },
  config: undefined,
  loading: false,
  error: false,
  mainNavigationItems: [],
  isSidebarOpen: true,
};

const reducer: Reducer<AppState> = (state = initialState, action) => {
  switch (action.type) {
    case abpConfig.FetchAbpApplicationConfig.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case abpConfig.FetchAbpApplicationConfig.SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.payload.currentUser,
          permissions: Object.keys(action.payload.auth.grantedPolicies).map(perm => ({
            name: perm,
          })),
        },
        appConfig: action.payload,
      };
    }
    case NavigationActionTypes.INITIALISE_MAIN_NAVIGATION: {
      return {
        ...state,
        mainNavigationItems: action.navigationItems,
      };
    }
    case NavigationActionTypes.TOGGLE_MAIN_NAVIGATION_ITEM: {
      return {
        ...state,
        mainNavigationItems:
          state.mainNavigationItems?.length === 0
            ? state.mainNavigationItems
            : state.mainNavigationItems?.map(item =>
                item.key === action.itemKey
                  ? {
                      ...item,
                      open: !item.open,
                    }
                  : item
              ),
      };
    }
    case AppActionTypes.INITIATE_APP_LOAD:
      return {
        ...state,
        loading: true,
      };
    case AppActionTypes.APP_LOADED:
      return {
        ...state,
        loading: false,
      };
    case AppActionTypes.TOGGLE_SIDEBAR_DRAWER:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    default:
      return state;
  }
};

export default reducer;
