import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';

import NavigationGroup, { NavigationListItem } from './NavigationGroup';
import styles from './MainNavigation.styles';
import { useNavigation } from '../../NavigationContext';

const MainNavigation = () => {
  const { menu, paths, move, toggle } = useNavigation();
  const classes = makeStyles(styles)();

  return (
    <List component="nav" className={classes.root}>
      <NavigationListItem itemKey="home" text="Home" route="/" move={move} toggle={() => {}} />
      {menu &&
        menu.items?.map(item => (
          <Fragment key={item.elementId}>
            <NavigationGroup
              item={item}
              paths={paths?.map(x => x.elementId) || []}
              move={move}
              toggle={toggle}
            />
          </Fragment>
        ))}
    </List>
  );
};

export default MainNavigation;
