/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useBoolean } from 'react-use';
import { ReportDto } from 'modules/Shared/models';
import SearchBox from 'modules/Autocomplete';
import { useNavigation } from 'modules/Menu';
import useReportSearch from './useReportSearch';

export default function ReportSearch({
  onSelect,
  renderInput: render,
  clearOnBlur = false,
  ...props
}: {
  onSelect?: (report: ReportDto) => void;
  renderInput?: any;
  clearOnBlur?: boolean;
} & Record<string, unknown>) {
  const { hits, refine, loading, clear } = useReportSearch();
  const { move, flat, current } = useNavigation();
  const [open, toggle] = useBoolean(false);

  const renderInput = React.useMemo(() => {
    if (!render) return undefined;

    return p =>
      render({
        ...p,
        loading,
      });
  }, [loading, render]);

  return (
    <SearchBox
      key={current?.elementId}
      open={open && !!hits?.length}
      options={hits?.map((x: ReportDto) => ({
        label: String(x.title),
        ...x,
      }))}
      refine={refine}
      onOpen={() => toggle()}
      onClose={() => {
        if (clearOnBlur) {
          clear();
        }
        toggle();
      }}
      onChange={(_, item) => {
        if (!item) return;

        if (onSelect) {
          onSelect(item);
          return;
        }

        const route = flat.find(x => x.elementId === item.id);

        if (!route) return;

        move(route.url);
      }}
      loading={loading}
      noOptionsText="No results, start typing to search..."
      clearOnBlur={clearOnBlur}
      renderInput={renderInput}
      {...props}
    />
  );
}
