/* istanbul ignore file */
import AggregateRoot from './AggregateRoot';

export default class CreationAuditedAggregateRoot<T> extends AggregateRoot<string> {
  creationTime?: string;

  creatorId?: string;

  constructor(initialValues: Partial<CreationAuditedAggregateRoot<T>> = {}) {
    super(initialValues);
    this.creationTime = initialValues.creationTime;
    this.creatorId = initialValues.creatorId;
  }
}
