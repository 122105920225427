import axios, { AxiosResponse } from 'axios';

export default function getAsync(apiUrl: string) {
  return function api<TResponse>(id: string | undefined | null): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/api/app/profile/${id}`,
          method: 'GET',
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
