/* eslint-disable func-names */
import React, { forwardRef } from 'react';

import MaterialTable, { Icons, MaterialTableProps } from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TableCell from './TableCell';
import TableHeader from './TableHeader';

const tableIcons: Icons = {
  Add: forwardRef((props: never, ref) => (
    <AddBox {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Check: forwardRef((props: never, ref) => (
    <Check {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Clear: forwardRef((props: never, ref) => (
    <Clear {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Delete: forwardRef((props: never, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  DetailPanel: forwardRef((props: never, ref) => (
    <ChevronRight {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Edit: forwardRef((props: never, ref) => (
    <Edit {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Export: forwardRef((props: never, ref) => (
    <SaveAlt {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Filter: forwardRef((props: never, ref) => (
    <FilterList {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  FirstPage: forwardRef((props: never, ref) => (
    <FirstPage {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  LastPage: forwardRef((props: never, ref) => (
    <LastPage {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  NextPage: forwardRef((props: never, ref) => (
    <ChevronRight {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  PreviousPage: forwardRef((props: never, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ResetSearch: forwardRef((props: never, ref) => (
    <Clear {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Search: forwardRef((props: never, ref) => (
    <Search {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  SortArrow: forwardRef((props: never, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ThirdStateCheck: forwardRef((props: never, ref) => (
    <Remove {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ViewColumn: forwardRef((props: never, ref) => (
    <ViewColumn {...props} ref={ref} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default function <RowData extends object>({
  icons = tableIcons,
  columns,
  ...props
}: MaterialTableProps<RowData>) {
  return (
    <MaterialTable
      icons={icons}
      components={{
        Cell: TableCell,
        Header: TableHeader,
      }}
      columns={[...columns].map(x => ({ ...x }))}
      {...props}
    />
  );
}
