import { RSAA } from 'redux-api-middleware';
import { Middleware } from 'redux';

const apiBaseUrlMiddleware: Middleware = store => next => action => {
  const callApi = action[RSAA];
  const baseUri = store.getState().app.config.api.base_uri;
  const oidcBaseUri = store.getState().app.config.api.oidc_base_uri;

  if (!callApi) return next(action);

  if (callApi.endpoint.includes(':oidc:'))
    callApi.endpoint = callApi.endpoint.replace(':oidc:', oidcBaseUri);
  else {
    callApi.endpoint = `${baseUri}${callApi.endpoint}`;
  }

  return next(action);
};

export default apiBaseUrlMiddleware;
