import React from 'react';
import { useSelector } from 'react-redux';
import { AppConfig } from '@ldx-dmp/Core/types';
import { getConfig } from '@ldx-dmp/Core/reducers/selectors';
import buildApi from './api';

export default function useServer() {
  const config = useSelector(getConfig) as AppConfig;
  const apiUrl = config.api.base_uri;

  const api = React.useMemo(() => buildApi(apiUrl), [apiUrl]);

  return api;
}
