/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import { ArrowRightAlt as InfoIcon } from '@material-ui/icons';
import { ApplicationMenuItem } from 'modules/Shared/models';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      padding: '2px',
    },
    gridList: {
      width: '100%',
      height: '100%',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    item: {
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.7,
      },
    },
    itemTitle: {},
  })
);

export default function TitlebarGridList({
  item,
  move,
}: {
  item?: ApplicationMenuItem;
  move: any;
}) {
  const classes = useStyles();
  const [path, setPath] = React.useState([item]);

  React.useEffect(() => {
    if (path.length === 1 && !path[0] && item) {
      setPath([item]);
    }
  }, [item, path, path.length]);

  return (
    <div className={classes.root}>
      <GridList cellHeight={200} className={classes.gridList} cols={3} spacing={4}>
        {path.length > 1 && (
          <Button
            onClick={() => {
              const n = [...path];
              n.pop();
              setPath(n);
            }}
          >
            Back
          </Button>
        )}
        {path[path.length - 1]?.items
          ?.filter(x => x.customData?.type === 'Category')
          .map(tile => {
            const subtitleContent = tile.customData?.subtitle;
            const subtitle = subtitleContent ? <>{subtitleContent}</> : undefined;

            return (
              <GridListTile
                className={classes.item}
                key={tile.elementId}
                onClick={() => {
                  move(tile.url);
                }}
              >
                <img
                  src={String(tile.customData?.image || 'https://via.placeholder.com/200')}
                  alt={tile.displayName}
                />
                <GridListTileBar
                  className={classes.itemTitle}
                  title={tile.displayName}
                  subtitle={subtitle}
                  actionIcon={
                    <IconButton className={classes.icon}>
                      <InfoIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            );
          })}
      </GridList>
    </div>
  );
}
