import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActionCreators } from 'modules/Core/actions';
import styles from './ExpandCollapseToggle.styles';

const ExpandCollapseIcon = ({ open = true }) => (
  <>
    {open && <ChevronLeft />}
    {!open && <ChevronRight />}
  </>
);

const ExpandCollapseToggle = ({ open = true }) => {
  const classes = makeStyles(styles)();
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    dispatch(navigationActionCreators.toggleSidebarDrawer());
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleSidebarToggle}>
        <ExpandCollapseIcon open={open} />
      </IconButton>
    </div>
  );
};

ExpandCollapseIcon.propTypes = {
  open: PropTypes.bool,
};

ExpandCollapseToggle.propTypes = {
  open: PropTypes.bool,
};

export default ExpandCollapseToggle;
