import { get } from 'lodash';
import { createSelector } from 'reselect';
import { ApiError, DialogState, EditingState } from 'modules/Common/types';

export const getNavigationItems = state => state.app.mainNavigationItems;

export const getNavigationItem = createSelector(
  getNavigationItems,
  (__, itemKey) => itemKey,
  (navItems, itemKey) => navItems.find(item => item.key === itemKey)
);

export const sidebarDrawerIsOpen = state => state.app.isSidebarOpen;

export const getUserProfile = state =>
  state.app.user.profile ? state.app.user.profile : { name: 'Unknown', surName: 'User' };

export const getUserDisplayName = createSelector(
  getUserProfile,
  profile => `${profile.name ?? profile.userName} ${profile.surName ? profile.surName : ''}`
);

export const getUserId = state => state.oidc?.user?.profile?.sub;

export const isAppLoaded = state =>
  !state.app.loading && state.app.user.profile && state.app.user.permissions;

export const getUserPermissions = state => state.app.user.permissions;

export const makeUserHasPermission = () =>
  createSelector(
    getUserPermissions,
    (__, permission) => permission,
    (userPermissions, permission) =>
      userPermissions ? userPermissions.find(perm => perm.name === permission) !== undefined : false
  );

export const createLoadingSelector = actions => state => {
  return actions.some(action => get(state, `apiLoading.${action}`));
};

export const createDialogStateSelector = action => state => {
  return get(state, `dialogs.${action}`);
};

export const createApiErrorSelector = action => (state): ApiError | null => {
  return get(state, `apiErrors.${action}`);
};

export const getAppConfig = state => state.app.appConfig;
export const getAppSettings = createSelector(
  getAppConfig,
  (config = {}) => config?.setting?.values || {}
);
export const getConfig = state => state.app.config;
export const getApiConfig = createSelector(getConfig, config => config.api);
export const getApiBaseUrl = createSelector(getApiConfig, api => api.base_uri);

export const getOpenDialogs = state => {
  const keys = Object.keys(state.dialogs);
  const openDialogs: string[] = [];
  keys.forEach(key => {
    if (state.dialogs[key] === DialogState.Open) openDialogs.push(key);
  });

  return keys;
};
export const getActualOpenDialogs = state => {
  const keys = Object.keys(state.dialogs);
  const openDialogs: string[] = [];
  keys.forEach(key => {
    if (state.dialogs[key] === DialogState.Open) openDialogs.push(key);
  });

  return openDialogs;
};

export const getOpenDialogsStartingWith = search =>
  createSelector(getActualOpenDialogs, opened => opened.filter(x => x.startsWith(search)));

export const getItemsBeingEdited = state => {
  const keys = Object.keys(state.editMode);
  const items: { key: string; itemId: string }[] = [];
  keys.forEach(key => {
    if (state.editMode[key].state === EditingState.Editing)
      items.push({ key, itemId: state.editMode[key].itemId });
  });

  return items;
};
