/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import Form from './Form';

export function DialogForm<T>({
  open,
  onFormClose = () => {},
  onFormExit = () => {},
  form,
  onFormSubmit,
  title,
  buttonText = 'Save',
  initialValues = {},
  ...props
}: {
  open: boolean;
  onFormClose?: () => void;
  onFormExit?: () => void;
  form: any;
  onFormSubmit: (data: T) => Promise<unknown>;
  title?: string;
  buttonText?: string;
  initialValues: any;
} & Record<string, any>) {
  const [state, toggle] = React.useState<'opened' | 'closed' | 'closing'>('closed');

  const handleClose = React.useCallback(() => {
    toggle('closing');
    onFormClose();
  }, [onFormClose]);

  const handleExit = () => {
    toggle('closed');
    onFormExit();
  };

  const handleSubmit = async data => {
    await onFormSubmit(data);
  };

  React.useEffect(() => {
    if (open && state === 'closed') {
      toggle('opened');
    }

    if (!open && state === 'opened') {
      handleClose();
    }
  }, [state, open, toggle, handleClose]);

  return (
    <Dialog open={state === 'opened'} onClose={handleClose} onExited={handleExit} fullWidth>
      <Form
        {...form}
        {...props}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onClose={handleClose}
      >
        {({ onSubmit, isSubmitting, content }) => (
          <>
            {title && <DialogTitle style={{ paddingBottom: 0 }}>{title}</DialogTitle>}
            <DialogContent style={{ paddingTop: 0 }}>{content}</DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
                {buttonText}
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
}

export default DialogForm;
