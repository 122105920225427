import React from 'react';
import { utils } from '@rjsf/core';
import { Theme } from '@rjsf/material-ui';
import { Formik } from 'formik';
import { AutoSave } from '@ldx-dmp/AutoSave';

import FormikDatePicker from '@ldx-dmp/Common/components/FormikDatePicker';
import FormikDateTimePicker from '@ldx-dmp/Common/components/FormikDateTimePicker';
import FormikCheckbox from '@ldx-dmp/Common/components/FormikCheckbox';
import ApiErrors, { ApiErrorType } from '@ldx-dmp/Common/components/ApiErrors';

import { JsonFormProps } from './JsonFormProps';
import { toIdSchema } from './utils';
import TextWidget from './TextWidget';
import SelectWidget from './SelectWidget';
import RadioWidget from './FormikRadioButtonGroupWidget';
import ArrayField from './ArrayField';
import FileWidget from './FileWidget';

const { getDefaultRegistry, retrieveSchema } = utils;
const { fields, widgets, ...defaultRegistery } = getDefaultRegistry();

export function JsonForm<T>({
  onSubmit,
  formData: initialValues,
  schema,
  uiSchema = {},
  widgets: _widgets,
  fields: _fields,
  ObjectFieldTemplate,
  FieldTemplate,
  children,
  disabled,
  validationSchema,
  onSave,
  enableReinitialize,
  formContext,
  autoSaveEnabled = true,
  autoSaveId,
  autoSaveTag,
  topButtons = false,
}: JsonFormProps<T>) {
  const [serverValidationErrors, setServerValidationErrors] = React.useState<
    ApiErrorType | undefined
  >();

  const registry = {
    ...defaultRegistery,
    ...Theme,
    widgets: {
      ...widgets,
      ...Theme.widgets,
      ..._widgets,
      TextWidget,
      DateWidget: FormikDatePicker,
      DateTimeWidget: FormikDateTimePicker,
      CheckboxWidget: FormikCheckbox,
      SelectWidget,
      RadioWidget,
      FileWidget,
    },
    fields: {
      ...fields,
      ...Theme.fields,
      ..._fields,
      ArrayField,
    },
    ObjectFieldTemplate,
    FieldTemplate,
  };
  const { SchemaField } = registry.fields;
  const rootSchema = schema;
  const retrievedSchema = retrieveSchema(schema, rootSchema, initialValues);

  const idSchema = toIdSchema(
    retrievedSchema,
    uiSchema['ui:rootFieldId'],
    rootSchema,
    initialValues
  );

  const handleOnSave = React.useCallback(
    (values: Partial<T>) =>
      new Promise((resolve, reject) => {
        if (!onSave) {
          resolve(values);
          return;
        }

        onSave(values as T).then(resolve, reject);
      }),
    [onSave]
  );

  const noop = React.useCallback(() => {}, []);

  const handleSubmit = React.useCallback(
    (values: Partial<T>) =>
      new Promise((resolve, reject) => {
        if (!onSubmit) {
          resolve(values);
          return;
        }

        onSubmit(values as T).then(resolve, response => {
          setServerValidationErrors(response?.data?.error);
          reject(response);
        });
      }),
    [onSubmit]
  );

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={enableReinitialize}
    >
      {form => (
        <form noValidate autoComplete="on" onSubmit={form.handleSubmit}>
          {onSave && autoSaveEnabled && (
            <AutoSave onSave={handleOnSave} id={autoSaveId} tag={autoSaveTag} />
          )}
          {topButtons && (children || <></>)}
          {serverValidationErrors && <ApiErrors apiError={serverValidationErrors} />}
          {!children && (
            <SchemaField
              schema={retrievedSchema}
              uiSchema={uiSchema}
              errorSchema={{}}
              idSchema={idSchema}
              idPrefix=""
              formContext={formContext}
              formData={form.values}
              onChange={noop}
              onBlur={noop}
              onFocus={noop}
              registry={registry}
              disabled={disabled}
            />
          )}
          {(children &&
            children({
              onSubmit: form.handleSubmit,
              ...form,
              content: (
                <SchemaField
                  schema={retrievedSchema}
                  uiSchema={uiSchema}
                  errorSchema={{}}
                  idSchema={idSchema}
                  idPrefix=""
                  formContext={formContext}
                  formData={form.values}
                  onChange={noop}
                  onBlur={noop}
                  onFocus={noop}
                  registry={registry}
                  disabled={disabled}
                />
              ),
            })) || <></>}
        </form>
      )}
    </Formik>
  );
}

export default JsonForm;
