/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './categoryInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      title: {
        type: 'string',
        title: 'Title',
      },
      subtitle: {
        type: 'string',
        title: 'Subtitle',
      },
      report: {
        type: 'string',
        title: 'Report',
      },
      parent: {
        type: 'string',
        title: 'Parent',
      },
      updateSchedule: {
        type: 'string',
        title: 'Update Schedule',
      },
      status: {
        title: 'Status',
        type: 'string',
        enum: ['Draft', 'Published'],
        enumNames: ['Draft', 'Published'],
      },
      order: {
        type: 'number',
        title: 'Order',
      },
      image: {
        type: 'string',
        format: 'data-url',
        title: 'Image',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  title: '',
  subtitle: '',
  report: '',
  parent: '',
  updateSchedule: '',
  status: '',
  order: 0,
  image: '',
});
