/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';

export function useServer() {
  const config = useSelector(getConfig) as AppConfig;
  const apiUrl = config.api.base_uri;

  return React.useMemo(
    () => ({
      search: search => {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
          axios.get<any>(`${apiUrl}/users/search?search=${search}`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      searchUsersAndGroups: search => {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
          axios.get<any>(`${apiUrl}/access-control/search?search=${search}`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
    }),

    [apiUrl]
  );
}

export default useServer;
