/* eslint-disable import/prefer-default-export */
import mainNavigationItems from 'modules/Core/data/mainNavigationItems';

export const createMainNavigation = permissions => {
  return mainNavigationItems()
    .filter(
      item =>
        permissions.find(
          perm => perm.name === item.permission || item.permissions?.includes(perm.name)
        ) !== undefined
    )
    .map(item => {
      if (!item.children) return item;
      return {
        ...item,
        children: item.children.filter(
          child =>
            permissions.find(
              perm => perm.name === child.permission || child.permissions?.includes(perm.name)
            ) !== undefined
        ),
      };
    });
};
