const listTableStyles = theme => ({
  tableWrapper: {
    maxHeight: 650,
    overflow: 'auto',
    width: '100%',
  },
  paper: {
    padding: theme.spacing(3),
  },
  rowRoot: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
      '&:hover': {
        backgroundColor: '#bde3f2',
      },
    },
    '&:hover': {
      backgroundColor: '#bde3f2',
    },
    '&.clickable': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  cellHead: {
    fontWeight: 'bold',
    backgroundColor: '#fff',
    fontFamily: 'ITCAvantGardeStd-Bk',
  },
  cellBody: {
    fontSize: 14,
  },
  noData: {
    textAlign: 'center',
  },
});

export default listTableStyles;
