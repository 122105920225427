import React from 'react';
import { useEffectOnce } from 'react-use';
import PropTypes from 'prop-types';
import { LoadingScreen } from 'modules/Common/components';
import authManager from '../authManager';

const LoginPage = ({ location }) => {
  const userManager = authManager.getUserManager();
  useEffectOnce(() => {
    const data = {
      state: {
        redirectUrl: new URLSearchParams(location.search).returnTo,
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    userManager.signinRedirect(data);
  });

  return <LoadingScreen message="Redirecting to login..." />;
};

LoginPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default LoginPage;
