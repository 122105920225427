import { ExtensibleCreationAuditedEntityDto } from './ExtensibleCreationAuditedEntityDto';

export class ExtensibleAuditedEntityDto<T = string> extends ExtensibleCreationAuditedEntityDto<T> {
  lastModificationTime?: string;

  lastModifierId?: string;

  constructor(initialValues: Partial<ExtensibleAuditedEntityDto<T>> = {}) {
    super(initialValues);
  }
}

export default ExtensibleAuditedEntityDto;
