/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { DialogForm, DrawerForm } from '@ldx-dmp/JsonFormDialog';
import { useUserPreferences } from '@ldx-dmp/User';

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: theme.breakpoints.width('sm'),
  },
}));

export type EditorType = {
  state: 'adding' | 'editing' | 'deleting' | 'idle' | string;
  parent?: string | undefined | null;
  form?: any;
  initialValues?: Record<string, any>;
  title?: string;
  onSubmit?: any;
};

export type AdminPropType = {
  editorState: EditorType;
  closeEditor: () => void;
  submitEditor: (data?: any) => Promise<unknown>;
  exitEditor: () => void;
};

function Admin<TContext>({
  editorState,
  closeEditor,
  submitEditor,
  exitEditor,
}: TContext & AdminPropType & Record<string, any>) {
  const classes = useStyles();
  const {
    state: { drawer },
  } = useUserPreferences();

  React.useEffect(() => {
    if (editorState?.state === 'deleting') {
      submitEditor(editorState.initialValues).then(
        () => {},
        () => {}
      );
    }
  }, [editorState, submitEditor]);

  const Form = React.useMemo(
    () =>
      drawer
        ? ({ children, ...p }: React.PropsWithChildren<any>) => (
            <DrawerForm {...p} className={classes.drawer} anchor="right">
              {children}
            </DrawerForm>
          )
        : DialogForm,
    [classes.drawer, drawer]
  );

  if (editorState?.state === 'deleting') return <></>;

  return (
    <>
      <Form
        title={editorState?.title}
        onFormClose={closeEditor}
        onFormSubmit={submitEditor}
        onFormExit={exitEditor}
        open={['adding', 'editing'].includes(editorState?.state)}
        form={editorState?.form}
        initialValues={editorState?.initialValues}
      />
    </>
  );
}

export default Admin;
