/* istanbul ignore file */
import { EntityDto } from '@ldx-dmp/Core';

export default class UserProfileDto extends EntityDto {
  favourites?: string[];

  constructor(initialValues: Partial<UserProfileDto> = {}) {
    super(initialValues);
    this.favourites = initialValues.favourites;
  }
}
