/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useEffectOnce } from 'react-use';
import { useServer } from './useServer';

export default function useUserSearch() {
  const server = useServer();
  const [hits, setHits] = React.useState([]);

  const refine = React.useCallback(
    s => {
      server.search(s).then(
        response => {
          setHits(response.data.items);
        },
        () => {}
      );
    },
    [server]
  );

  useEffectOnce(() => {
    refine('');
  });

  return React.useMemo(
    () => ({
      hits,
      refine,
    }),
    [hits, refine]
  );
}
