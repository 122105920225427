/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ThumbUp from '@material-ui/icons/Done';

import { Snackbar, SnackbarProps } from '@material-ui/core';

const autoHideDuration = 2000;

const AutoSaveSuccessSnackbar = React.memo((props: SnackbarProps) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    autoHideDuration={autoHideDuration}
    {...props}
  >
    <>
      <ThumbUp color="primary" />
    </>
  </Snackbar>
));

export default AutoSaveSuccessSnackbar;
