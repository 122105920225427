/* eslint-disable no-param-reassign */
import { apiGet } from 'modules/Core/actions';

export interface TableState {
  searchText: string;
  rowsPerPage: number;
  page: number;
  sortOrder: {
    name: string;
    direction: string;
  };
  filterList?: Array<Array<string>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any[];
  columns?: Array<{
    name: string;
  }>;
  querystring?: { [key: string]: string };
}

function getPart(key, value) {
  if (!value) return '';

  return `${key}=${encodeURI(value)}`;
}

export function buildUrl(url, tableState: TableState, querystring: Record<string, unknown> = {}) {
  const { page } = tableState;

  const sorting = tableState.sortOrder.name
    ? `${tableState.sortOrder.name} ${tableState.sortOrder.direction}`
    : undefined;

  const filters =
    (tableState.columns &&
      tableState.filterList
        ?.map((items, index) => {
          return {
            name: tableState.columns && tableState.columns[index]?.name,
            items,
          };
        })
        .filter(x => x.items.length && x.name)) ||
    [];

  const maxResultCount = tableState.rowsPerPage;
  const skip = page * maxResultCount;
  const search = tableState.searchText;

  const parts = [
    getPart('skipCount', skip),
    getPart('maxResultCount', maxResultCount),
    getPart('sorting', sorting),
    getPart('search', search),
    ...filters.reduce((previous, current) => {
      const newItems = current.items.map((x, index) => {
        return getPart(`${current.name}[${index}]`, x);
      });
      return [...previous, ...newItems];
    }, [] as string[]),
  ];

  const additionalQuerystring = Object.entries(querystring ?? {}).map(([key, value]) =>
    getPart(key, value)
  );

  return `${url}?${[...additionalQuerystring, ...parts].filter(x => !!x).join('&')}`;
}

export const getTableData = (
  url: string,
  actionType: string,
  tableState: TableState,
  querystring: { [key: string]: string } = {}
) => apiGet(buildUrl(url, tableState, querystring), actionType);

export default {};
