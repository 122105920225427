import React from 'react';
import { useField } from 'formik';
import { DateTimePicker } from '@material-ui/pickers';

type FormikDateTimePickerProps = {
  name?: string;
  id?: string;
  label: string;
  // RJSF methods we want to ignore
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
};

const FormikDatePicker = ({
  name,
  id,
  label,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: FormikDateTimePickerProps) => {
  const [, meta, helpers] = useField(name || id || '');
  return (
    <DateTimePicker
      fullWidth
      autoOk
      showTodayButton
      name={name || id}
      inputVariant="filled"
      onAccept={date => helpers.setValue(date)}
      onChange={date => helpers.setValue(date)}
      value={meta.value}
      label={label}
      error={!!(meta.touched && meta.error)}
      {...rest}
    />
  );
};

export default FormikDatePicker;
