/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { CircularProgress } from '@material-ui/core';

type OptionType = {
  label: string;
};

export default function Search<T extends OptionType>({
  options,
  refine,
  getOptionLabel = (option: T) => option.label,
  loading,
  renderInput,
  ...props
}: {
  options: T[];
  refine: ((search: string) => Promise<void>) | ((search: string) => void);
  getOptionLabel?: (option: T) => string;
  loading?: boolean;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
} & Record<string, unknown>) {
  return (
    <Autocomplete
      loading={loading}
      options={options}
      onInputChange={(_, value) => {
        return refine(value || '');
      }}
      getOptionLabel={getOptionLabel}
      renderInput={
        renderInput ||
        (params => (
          <TextField
            {...params}
            label="Search"
            variant="outlined"
            margin="normal"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        ))
      }
      filterOptions={i => i}
      renderOption={(option: T, { inputValue }) => {
        const matches = match(option.label, inputValue);
        const parts = parse(option.label, matches);

        return (
          <div>
            {parts.map(part => (
              <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      {...props}
    />
  );
}
