/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfileDto } from './models';

type ProfileState = UserProfileDto;

const initialState = { favourites: [] } as ProfileState;

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    addFav(state, action: PayloadAction<string>) {
      if (!state.favourites?.includes(action.payload)) {
        state.favourites = [action.payload, ...(state.favourites || [])];
      }
      return state;
    },
    removeFav(state, action: PayloadAction<string>) {
      state.favourites = state.favourites?.filter(x => x !== action.payload);
      return state;
    },
    setProfile(_, action: PayloadAction<UserProfileDto>) {
      return action.payload;
    },
  },
});

export const { addFav, setProfile, removeFav } = userProfileSlice.actions;
export default userProfileSlice.reducer;
