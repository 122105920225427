import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Cog from 'mdi-material-ui/Cog';

import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useUserPreferences } from './useUserPreferences';

const Toggle = React.memo(
  ({ children, ...props }: React.PropsWithChildren<{ checked: boolean }>) => (
    <>
      <Switch {...props} /> {children}
    </>
  )
);

export default function SimpleMenu({ className }: { className: string }) {
  const { actions } = useUserPreferences();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton className={className} onClick={handleClick}>
        <Cog />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map(({ label, checked, onClick }) => (
          <MenuItem onClick={onClick} selected={checked}>
            <Toggle checked={checked}>{label}</Toggle>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
