import React from 'react';
import { ApmRoute } from '@elastic/apm-rum-react';
import { Switch } from 'react-router-dom';
import { useNavigation } from '@ldx-dmp/Menu';
import { CategoryView } from './features/view/containers/loadable';

const AppRouter = () => {
  const { flat } = useNavigation();

  return (
    <Switch>
      {flat
        ?.filter(x => x.customData?.type === 'Category')
        .map(item => (
          <ApmRoute
            exact
            path={item.url}
            key={item.url}
            component={() => <CategoryView item={item} />}
          />
        ))}
    </Switch>
  );
};

export default AppRouter;
