import React from 'react';

import { WidgetProps } from '@rjsf/core';
import FormikTextField, { FormikTextFieldProps } from '@ldx-dmp/Common/components/FormikTextField';

export type TextWidgetProps = WidgetProps & FormikTextFieldProps;

export const TextWidget = ({
  schema,
  id,
  label,
  required,
  disabled,
  uiSchema,
}: TextWidgetProps) => {
  const options = uiSchema['ui:options'] || {};
  return (
    <FormikTextField
      name={id}
      label={label || schema.title || ''}
      required={required}
      type={schema.type as string}
      disabled={disabled}
      {...options}
    />
  );
};

export default TextWidget;
