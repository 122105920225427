import { DialogState } from 'modules/Common/types';

export default (state = {}, action) => {
  const { type } = action;
  const matches = /(.*_DIALOG)_(OPEN|CLOSE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'OPEN' ? DialogState.Open : DialogState.Closed,
  };
};
