import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { push } from 'connected-react-router';
import { LoadingScreen } from 'modules/Common';
import authManager from '../authManager';

const CallbackPage = ({ dispatch }) => {
  const userManager = authManager.getUserManager();

  const getReturnUrl = redirectUrl => {
    if (!redirectUrl || redirectUrl === '/auth/login' || redirectUrl === '/auth/callback')
      return '/';

    return redirectUrl;
  };

  const successCallback = user => {
    userManager.startSilentRenew();
    dispatch(push(getReturnUrl(user.state.redirectUrl)));
  };

  return (
    <CallbackComponent userManager={userManager} successCallback={successCallback}>
      <LoadingScreen message="Returning to application..." />
    </CallbackComponent>
  );
};

CallbackPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(CallbackPage);
