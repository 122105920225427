import React from 'react';
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import Home from './containers/Home';
import { rootPath } from './constants';

const routes = () => {
  return (
    <>
      <Switch>
        <ApmRoute path={rootPath} component={Home} />
      </Switch>
    </>
  );
};

routes.path = rootPath;

export { entityPath } from './constants';
export default routes;
