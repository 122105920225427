import React from 'react';
import { useEffectOnce } from 'react-use';
import useServer from './useServer';

export type TrackFunction = (data: Record<string, unknown>) => void;

export interface TrackReturnType {
  track: TrackFunction;
}

export default ({
  auto,
  ...options
}: {
  auto?: boolean;
  defaults: Record<string, unknown>;
}): TrackReturnType => {
  const server = useServer();

  const track = React.useCallback(
    async action => {
      try {
        await server.postAsync({
          ...options.defaults,
          ...action,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [options.defaults, server]
  ) as (data: Record<string, unknown>) => void;

  useEffectOnce(() => {
    if (auto) {
      track(options.defaults);
    }
  });

  return React.useMemo(
    () => ({
      track,
    }),
    [track]
  );
};
