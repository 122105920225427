import createAsync from './createAsync';
import updateAsync from './updateAsync';
import deleteAsync from './deleteAsync';
import getAsync from './getAsync';
import getListAsync from './getListAsync';
import getDataTableAsync from './getDataTableAsync';
// imports

export default apiUrl => ({
  createAsync: createAsync(apiUrl),
  updateAsync: updateAsync(apiUrl),
  deleteAsync: deleteAsync(apiUrl),
  getAsync: getAsync(apiUrl),
  getListAsync: getListAsync(apiUrl),
  getDataTableAsync: getDataTableAsync(apiUrl),
  // exports
});
