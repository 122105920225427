/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, takeEvery, put, delay, call, race, take, select } from 'redux-saga/effects';

import {
  apiCallRegister,
  apiSyncStarted,
  apiSyncFinished,
  selectTimeout,
  selectShouldSync,
  flushApiCalls,
} from './autoSaveSlice';

const registry: any[] = [];

export function* save(items: typeof registry, onError: any = () => {}) {
  for (let index = 0; index < items.length; index += 1) {
    const element = items[index];
    try {
      yield call(element.payload.func);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (onError) {
        onError(error, element);
      } else {
        throw error;
      }
    }
  }
}

export function* autoSave() {
  while (true) {
    const timeout = yield select(selectTimeout);

    yield delay(timeout);

    const shouldSync = yield select(selectShouldSync);

    if (shouldSync) {
      // eslint-disable-next-line no-console
      console.debug('checking autosave...');
      if (registry.length) {
        yield put(apiSyncStarted());
        yield call(save, registry.splice(0), (_error: any, element: any) => registry.push(element));
        yield put(apiSyncFinished());
      }
    } else {
      // eslint-disable-next-line no-console
      console.debug('autosave paused');
    }
  }
}

export function* flush(id) {
  let flushIndex = registry.findIndex(x => x.payload.id === id);

  while (flushIndex > -1) {
    const item = registry.splice(flushIndex, 1);
    yield call(save, item, (_error: any, element: any) => registry.push(element));
    flushIndex = registry.findIndex(x => x.payload.id === id);
  }
}

export function* registerApiCall(action: ReturnType<typeof apiCallRegister>) {
  const index = registry.findIndex(
    x => x.payload.id === action.payload.id && x.payload.tag === action.payload.tag
  );
  if (index > -1) {
    registry.splice(index, 1);
  }

  yield call(() => registry.push(action));

  if (action.payload.flush) {
    yield call(flush, action.payload.id);
  }
}

function* startAutoSaveLoop() {
  // eslint-disable-next-line no-console
  console.debug('autosave started');

  yield call(autoSave);

  // eslint-disable-next-line no-console
  console.debug('autosave stopped');
}

export function* handleFlushApiCalls(action: ReturnType<typeof flushApiCalls>) {
  if (!action.payload.id) {
    yield call(save, registry.splice(0), (_error: any, element: any) => registry.push(element));
  } else {
    yield call(flush, action.payload.id);
  }

  if (action.payload.next) yield call(action.payload.next);
}

export function* watch() {
  yield takeEvery(flushApiCalls, handleFlushApiCalls);
  yield takeEvery(apiCallRegister, registerApiCall);
  yield call(startAutoSaveLoop);
}

export default function* rootSaga() {
  yield race({
    main: all([watch()]),
    cancel: take('autosave/stop'),
  });

  if (registry.length) yield call(save, registry.splice(0));
}
