import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import listTableStyles from './ListTable.styles';

const useStyles = makeStyles(listTableStyles);

const ListTableCell = ({ children, classes }) => {
  return (
    <TableCell classes={{ head: classes.cellHead, body: classes.cellBody }}>{children}</TableCell>
  );
};

const ListTableRow = ({ classes, columns, rowData, rowKeyAccessor, onRowClicked = null }) => {
  const clickable = onRowClicked !== null;
  const handleClick = () => {
    if (!onRowClicked) return;

    const code = rowData[rowKeyAccessor];
    onRowClicked(code);
  };

  return (
    <TableRow className={clsx(classes.rowRoot, clickable && 'clickable')} onClick={handleClick}>
      {columns.map(col => (
        <ListTableCell key={`${rowData[rowKeyAccessor]}-${col.accessor}`} classes={classes}>
          {rowData[col.accessor]}
        </ListTableCell>
      ))}
    </TableRow>
  );
};

const ListTable = ({
  tableData: { columns, rows, rowKeyAccessor },
  onRowClicked = null,
  noPaper,
}) => {
  const classes = useStyles();

  const table = (
    <div className={classes.tableWrapper}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            {columns.map(col => (
              <ListTableCell key={col.accessor} classes={classes}>
                {col.name}
              </ListTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.length > 0 &&
            rows.map(row => (
              <ListTableRow
                key={row[rowKeyAccessor]}
                classes={classes}
                columns={columns}
                rowData={row}
                rowKeyAccessor={rowKeyAccessor}
                onRowClicked={onRowClicked}
              />
            ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell className={classes.noData} colSpan={columns.length}>
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );

  if (!noPaper) return <Paper className={classes.paper}>{table}</Paper>;

  return table;
};

ListTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  classes: PropTypes.shape({
    cellHead: PropTypes.string.isRequired,
    cellBody: PropTypes.string.isRequired,
  }).isRequired,
};

ListTableRow.propTypes = {
  classes: PropTypes.shape({
    rowRoot: PropTypes.string.isRequired,
  }).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowKeyAccessor: PropTypes.string.isRequired,
  rowData: PropTypes.object.isRequired,
  onRowClicked: PropTypes.func,
};

ListTable.propTypes = {
  tableData: PropTypes.shape({
    columns: ListTableRow.propTypes.columns,
    rows: PropTypes.array.isRequired,
    rowKeyAccessor: PropTypes.string.isRequired,
  }).isRequired,
  onRowClicked: PropTypes.func,
  noPaper: PropTypes.bool,
};

ListTable.defaultProps = {
  noPaper: false,
};

export default ListTable;
