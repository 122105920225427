import { ExtensibleEntityDto } from './dtos';

export class ExtensibleCreationAuditedEntityDto<T = string> extends ExtensibleEntityDto<T> {
  creationTime?: string;

  creatorId?: string;

  constructor(initialValues: Partial<ExtensibleCreationAuditedEntityDto<T>> = {}) {
    super(initialValues);
  }
}

export default ExtensibleCreationAuditedEntityDto;
