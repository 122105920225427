import React from 'react';
import { Field } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';

export type FormikTextFieldProps = TextFieldProps & {
  name: string;
  label: string;
  step?: number | undefined;
  meta?: {
    error?: string;
    touched: boolean;
    initialTouched: boolean;
    initialError?: string;
  };
};

const FormikTextField = ({
  name,
  onChange,
  onBlur,
  id,
  onFocus,
  ...rest
}: FormikTextFieldProps) => {
  return (
    <Field name={name || id}>
      {({ field, meta }) => (
        <TextField
          variant="filled"
          fullWidth
          error={meta.touched && !!meta.error}
          name={name || id}
          {...rest}
          {...field}
        />
      )}
    </Field>
  );
};

export default FormikTextField;
