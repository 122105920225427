/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, CircularProgress, InputBase, Toolbar, Typography } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { selectors } from 'modules/Core/reducers';
import { VersionInfo } from 'modules/Common';
import { ReportSearch } from 'modules/Report';
import MiniLogo from './MiniLogo';
import UserDisplay from './UserDisplay';
import styles from './Topbar.styles';

const Topbar = () => {
  const isSidebarOpen = useSelector(state => selectors.sidebarDrawerIsOpen(state));
  const classes = makeStyles(styles as any)({ isSidebarOpen });

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          {!isSidebarOpen && <MiniLogo isSidebarOpen={isSidebarOpen} />}
          <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
            Data Reporting Platform
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <ReportSearch
              disablePortal
              limit={0}
              clearOnBlur
              renderInput={params => (
                <InputBase
                  ref={params.InputProps.ref}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  {...params}
                />
              )}
            />
          </div>
          <VersionInfo />
          <UserDisplay />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
