import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { FileDocumentEditOutline } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import styles from './ContentHeader.styles';
import NewItemButton from '../NewItemButton';

const ContentHeader = ({
  children,
  variant,
  component,
  title,
  withNewItem,
  newItemButtonText,
  newItemButtonOnClick,
  withEditItem,
  editItemButtonText,
  editItemButtonOnClick,
  subtitle,
}) => {
  const classes = makeStyles(styles)();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs>
          <Typography variant={variant} component={component}>
            {title}
            {subtitle && (
              <Typography variant="subtitle1" className={classes.subtitle} component="span">
                {subtitle}
              </Typography>
            )}
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid container alignItems="flex-start" justify="flex-end" direction="row">
            {withNewItem && (
              <NewItemButton text={newItemButtonText} onClick={newItemButtonOnClick} />
            )}
            {withEditItem && (
              <Button
                variant="contained"
                color="primary"
                onClick={editItemButtonOnClick}
                startIcon={<FileDocumentEditOutline />}
              >
                {editItemButtonText}
              </Button>
            )}
            {!withNewItem && !withEditItem && <>{children}</>}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

ContentHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.node.isRequired,
  variant: PropTypes.string,
  component: PropTypes.string,
  withNewItem: PropTypes.bool,
  newItemButtonText: PropTypes.string,
  newItemButtonOnClick: PropTypes.func,
  withEditItem: PropTypes.bool,
  editItemButtonText: PropTypes.string,
  editItemButtonOnClick: PropTypes.func,
  subtitle: PropTypes.string,
};

ContentHeader.defaultProps = {
  children: [],
  variant: 'h5',
  component: 'h2',
  withNewItem: false,
  newItemButtonText: 'New Item',
  newItemButtonOnClick: null,
  subtitle: '',
};

export default ContentHeader;
