import React from 'react';
import CategoryReportsList from 'modules/Category/features/view/containers/CategoryReportsList';
import { ReportDto } from 'modules/Shared/models';

export default ({ favourites }: { favourites: ReportDto[] }) => {
  return (
    <div
      style={{
        maxHeight: 300,
        overflowY: 'auto',
      }}
    >
      <CategoryReportsList title={<>Favourites</>} category={{}} reports={[...favourites]} />
    </div>
  );
};
