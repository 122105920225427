import { InjectableStore, SagaDescriptor } from '../storeTypes';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const Mode = {
  RESTART_ON_REMOUNT,
  DAEMON,
  ONCE_TILL_UNMOUNT,
};

export function injectSagaFactory(store: InjectableStore) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function injectSaga(key: string, descriptor: SagaDescriptor, ...args: any[]) {
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode || RESTART_ON_REMOUNT,
    };
    const { saga, mode } = newDescriptor;

    let hasSaga = Reflect.has(store.injectedSagas, key);

    /* istanbul ignore else */
    if (process.env.NODE_ENV !== 'production') {
      const oldDescriptor = store.injectedSagas[key];
      if (hasSaga && oldDescriptor?.saga !== saga) {
        // enable hot reloading of daemon and once-till-unmount sagas
        oldDescriptor?.task?.cancel();
        hasSaga = false;
      }
    }

    if (!hasSaga) {
      /* eslint-disable no-param-reassign */
      store.injectedSagas[key] = {
        ...newDescriptor,
        task: store.runSaga(saga, ...args),
      };
      /* eslint-enable no-param-reassign */
    }

    if (hasSaga && mode !== DAEMON && mode !== Mode.ONCE_TILL_UNMOUNT) {
      const current = store.injectedSagas[key];
      if (!current?.task?.isRunning()) {
        // eslint-disable-next-line no-param-reassign
        store.injectedSagas[key] = {
          ...newDescriptor,
          task: store.runSaga(saga, ...args),
        };
      }
    }
  };
}

export function ejectSagaFactory(store: InjectableStore) {
  return function ejectSaga(key: string) {
    /* istanbul ignore else */
    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];

      /* istanbul ignore next */
      if (!descriptor) {
        return;
      }

      if (descriptor.mode !== Mode.DAEMON && descriptor.task) {
        /* istanbul ignore next */
        // eslint-disable-next-line no-debugger
        debugger;
        if (descriptor.task.isRunning() || !descriptor.task.result()) {
          descriptor.task.end();
          descriptor.task.cancel();
        }
        // Clean up in production; in development we need `descriptor.saga` for hot reloading
        /* istanbul ignore next */
        if (process.env.NODE_ENV === 'production') {
          // Need some value to be able to detect `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
          store.injectedSagas[key] = undefined; // eslint-disable-line no-param-reassign
        }
      }
    }
  };
}

export function useSagaInjector(store: InjectableStore) {
  return {
    inject: injectSagaFactory(store),
    eject: ejectSagaFactory(store),
  };
}

export default useSagaInjector;
