/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import produce from 'immer';
import { useNavigation } from '@ldx-dmp/Menu';
import { EditorType } from '@ldx-dmp/Administration/Container';
import { SnackbarUtils } from '@ldx-dmp/Common';
import { AxiosResponse } from 'axios';
import { ReportDto } from '@ldx-dmp/Shared/models';
import * as createForm from '../create/reportInputFormSchema';
import { useServer } from '../../useServer';

function addTypes(f, types) {
  return produce(f, draft => {
    const options = types
      ?.filter(x => x.enabled)
      .map(x => ({
        label: x.title,
        value: x.key,
      }));

    draft.schema.properties.reportType.enum = options.map(x => x.value);
    draft.schema.properties.reportType.enumNames = options.map(x => x.label);
  });
}

export function useReportAdminContext() {
  const server = useServer();
  const { fetch, append, move, reload } = useNavigation();

  const [editorState, setEditorState] = React.useState<EditorType>({ state: 'idle' });

  const fetchTypes = React.useCallback(
    async function fetchTypes() {
      const response = await server.getTypes();
      return response.data;
    },
    [server]
  );

  const onAddReport = React.useCallback(
    async function onAddReport(parent = null) {
      const t = await fetchTypes();
      setEditorState(s => ({
        ...s,
        state: 'adding',
        parent,
        title: 'Add Report',
        form: addTypes(createForm, t?.items || []),
        initialValues: {
          categories: [parent],
        },
        onSubmit: async function a(data: any) {
          const response = await server.postReport(data);
          await fetch();

          setEditorState(ns => ({
            ...ns,
            state: 'idle',
          }));

          append(response.data.url);
        },
      }));
    },
    [append, fetch, fetchTypes, server]
  );

  const onEditReport = React.useCallback(
    function onEditReport(initialValues: any) {
      return new Promise<AxiosResponse<ReportDto>>(resolve => {
        setEditorState(s => ({
          ...s,
          state: 'editing',
          title: 'Edit Report',
          form: produce(createForm, (draft: any) => {
            draft.uiSchema.reportType = { 'ui:widget': 'hidden' };
          }),
          initialValues: createForm.mapToEdit(initialValues),
          onSubmit: async function a(data: any) {
            const response = await server.patchReport(data.id, data);

            setEditorState(ns => ({
              ...ns,
              state: 'idle',
            }));

            resolve(response);
          },
        }));
      });
    },
    [server]
  );

  const onDeleteReport = React.useCallback(
    function onDeleteReport(id) {
      return new Promise<void>((resolve, reject) => {
        setEditorState(s => ({
          ...s,
          state: 'deleting',
          parent: null,
          title: 'Delete Report',
          initialValues: { id },
          onSubmit: async function a(data: any) {
            try {
              await server.deleteReport(data.id);

              setEditorState(ns => ({
                ...ns,
                state: 'idle',
              }));

              move('/');
              resolve();
            } catch (error) {
              reject(error);
            }
          },
        }));
      });
    },
    [move, server]
  );

  const closeEditor = React.useCallback(() => {
    setEditorState(s => ({
      ...s,
      state: 'idle',
    }));
  }, []);

  const exitEditor = React.useCallback(() => {
    setEditorState(() => ({
      state: 'idle',
    }));
  }, []);

  const submitEditor = React.useCallback(
    async function submit(data) {
      try {
        await editorState.onSubmit(data);
      } catch (error) {
        SnackbarUtils.error('An error occured, please try again');
        throw error;
      }
    },
    [editorState]
  );

  return React.useMemo(
    () => ({
      onAddReport,
      editorState,
      setEditorState,
      closeEditor,
      submitEditor,
      exitEditor,
      onEditReport,
      onDeleteReport,
      reload,
    }),
    [
      onAddReport,
      editorState,
      closeEditor,
      submitEditor,
      exitEditor,
      onEditReport,
      onDeleteReport,
      reload,
    ]
  );
}

export default useReportAdminContext;
