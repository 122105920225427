/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Snackbar, SnackbarProps } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const autoHideDuration = 3000;

const AutoSaveEnabledSnackbar = React.memo((props: SnackbarProps) => (
  <Snackbar
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    autoHideDuration={autoHideDuration}
    {...props}
  >
    <Alert severity="success">Autosave enabled!</Alert>
  </Snackbar>
));

export default AutoSaveEnabledSnackbar;
