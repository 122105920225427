import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';
import { ChevronUp, ChevronDown } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';

import { navigationActionCreators } from '@ldx-dmp/Core/actions';
import { selectors } from '@ldx-dmp/Core/reducers';

import { ExpandableMenuItem } from '../../../NavigationContext';
import icons from './icons';
import styles from './NavigationItem.styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipWrapper = ({ show = false, text = null, children }: Record<string, any>) => (
  <>
    {show && (
      <Tooltip title={text} placement="right-end" arrow>
        <span>{children}</span>
      </Tooltip>
    )}
    {!show && <>{children}</>}
  </>
);

export const NavigationListItem = ({
  itemKey,
  text,
  iconKey = null,
  route = null,
  expandable = null,
  open = null,
  className = null,
  children,
  move,
  toggle,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any>) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (route) move(route);
    if (expandable) dispatch(navigationActionCreators.toggleMainNavigationItem(itemKey));
    toggle(itemKey);
  };

  return (
    <ListItem className={className}>
      {iconKey && <ListItemIcon>{icons[iconKey]}</ListItemIcon>}
      <ListItemText
        primaryTypographyProps={{ variant: 'subtitle1' }}
        primary={text}
        style={{
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
      {!!expandable && (
        <>
          {open ? (
            <ChevronUp
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                toggle(itemKey);
              }}
            />
          ) : (
            <ChevronDown
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                toggle(itemKey);
              }}
            />
          )}
        </>
      )}
      {children}
    </ListItem>
  );
};

const NavigationGroup = ({
  item,
  paths = [],
  move,
  toggle,
}: {
  item: ExpandableMenuItem;
  paths: (string | undefined)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>) => {
  const isSidebarOpen = useSelector(state => selectors.sidebarDrawerIsOpen(state));
  const classes = makeStyles(styles)();

  const kids = item?.items?.filter(x => x.customData?.type === 'Category');
  const open = paths.includes(item.elementId || '');

  useEffectOnce(() => {
    if (open) {
      toggle(item.elementId, true);
    }
  });

  return (
    <>
      <NavigationListItem
        itemKey={item.elementId}
        text={item.displayName}
        iconKey={item.icon}
        route={item.url}
        expandable={!item.isLeaf && kids?.length}
        open={item.open}
        move={move}
        toggle={toggle}
      />
      <Collapse in timeout="auto">
        {item.open && kids && (
          <List component="div" disablePadding>
            {kids.map(child => (
              <TooltipWrapper
                show={!isSidebarOpen}
                text={`${item.displayName} ${child.displayName}`}
                key={child.elementId}
              >
                <NavigationListItem
                  itemKey={child.elementId}
                  text={child.displayName}
                  iconKey={child.icon}
                  route={child.url}
                  className={clsx(
                    classes.menuItem,
                    isSidebarOpen && classes.nested,
                    child.cssClass
                  )}
                  open={child.open}
                  toggle={toggle}
                  move={move}
                />
              </TooltipWrapper>
            ))}
          </List>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

export default NavigationGroup;
