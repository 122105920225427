/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandableMenuItem } from 'modules/Menu';
import React from 'react';

export const defaultState = {
  id: undefined,
  drawer: true,
  categoryContent: 'left',
  recent: [],
  showVersion: false,
};

const UserPreferencesContext = React.createContext<Record<string, any>>(defaultState);

const getKey = () => `user_preferences`;

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(getKey());
    if (serializedState === null)
      return {
        ...defaultState,
      };

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'update': {
      const s = { ...state, ...action.payload };
      localStorage.setItem(getKey(), JSON.stringify(s));
      return s;
    }
    case 'recent': {
      const s = {
        ...state,
        recent: [
          {
            ...action.payload.item,
            added: new Date(),
          },
          ...(state.recent || []).filter(x => x.elementId !== action.payload.item.elementId),
        ] as ExpandableMenuItem[],
      };
      localStorage.setItem(getKey(), JSON.stringify(s));
      return s;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserPreferencesProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer(reducer, loadState());

  const { drawer, categoryContent, showVersion } = state;

  const toggleDrawer = React.useCallback(() => {
    dispatch({
      type: 'update',
      payload: {
        drawer: !drawer,
      },
    });
  }, [drawer]);

  const toggleCategoryContent = React.useCallback(() => {
    dispatch({
      type: 'update',
      payload: {
        categoryContent: categoryContent === 'right' ? 'left' : 'right',
      },
    });
  }, [categoryContent]);

  const toggleVersion = React.useCallback(() => {
    dispatch({
      type: 'update',
      payload: {
        showVersion: !showVersion,
      },
    });
  }, [showVersion]);

  // NOTE: you *might* need to memoize this value

  // Learn more in http://kcd.im/optimize-context

  const value = React.useMemo(
    () => ({
      state,
      dispatch,
      actions: [
        {
          label: 'Use Drawers',
          onClick: toggleDrawer,
          checked: drawer,
        },
        {
          label: 'Toggle Layout',
          onClick: toggleCategoryContent,
          checked: categoryContent === 'right',
        },
        {
          label: 'Toggle Version',
          onClick: toggleVersion,
          checked: showVersion,
        },
      ],
    }),
    [
      categoryContent,
      drawer,
      showVersion,
      state,
      toggleCategoryContent,
      toggleDrawer,
      toggleVersion,
    ]
  );

  return (
    <UserPreferencesContext.Provider value={value}>{children}</UserPreferencesContext.Provider>
  );
}

function useUserPreferences() {
  const context = React.useContext(UserPreferencesContext);

  if (context === undefined) {
    throw new Error('useUserPreferences must be used within a UserPreferencesProvider');
  }

  return context;
}

export { UserPreferencesProvider, useUserPreferences };
