/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Admin, { AdminPropType } from 'modules/Administration/Container';
import CategoryAdminContext from '../CategoryAdminContext';
import { useCategoryAdminContext } from '../useCategoryAdminContext';

export default ({ children }: React.PropsWithChildren<unknown>) => {
  const context = useCategoryAdminContext();
  return (
    <CategoryAdminContext.Provider value={context}>
      <CategoryAdminContext.Consumer>
        {values => (
          <>
            <Admin {...((values as unknown) as AdminPropType)} />
            {children}
          </>
        )}
      </CategoryAdminContext.Consumer>
    </CategoryAdminContext.Provider>
  );
};
