import React from 'react';
import produce from 'immer';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useEffectOnce } from 'react-use';
import flattenDeep from 'lodash/flattenDeep';
import { matchPath } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import ApplicationMenuItem from 'modules/Shared/models/ApplicationMenuItem';
import { useServer } from './useServer';

export type ExpandableMenuItem = {
  open?: boolean;
  items: ExpandableMenuItem[];
} & ApplicationMenuItem;

function useNavContext() {
  const dispatch = useDispatch();

  const server = useServer();
  const [menu, setMenu] = React.useState<ExpandableMenuItem | undefined>();
  const [current, setCurrent] = React.useState<ExpandableMenuItem | undefined>();
  const [state, setState] = React.useState('idle');

  const location = useLocation();
  const history = useHistory();

  const flat = React.useMemo(() => {
    function getItems({ items = [] }: ExpandableMenuItem) {
      return [...items, ...items.map(item => [...getItems(item)])];
    }

    const routes = menu ? flattenDeep([...getItems(menu)]) : [];

    return routes;
  }, [menu]);

  const paths = React.useMemo<ExpandableMenuItem[]>(() => {
    return flat
      .map(x => ({
        ...x,
        match: matchPath(location.pathname, {
          path: x.url,
          exact: false,
          strict: false,
        }),
      }))
      .filter(x => x.match);
  }, [flat, location.pathname]);

  React.useEffect(() => {
    const item = flat.find(x => x.url === location.pathname);
    setCurrent(item);
  }, [flat, location.pathname]);

  const fetch = React.useCallback(
    () =>
      server.getAll().then(response => {
        if (response.data.items && response.data.items.length)
          setMenu(response.data.items[0] as ExpandableMenuItem);
      }),
    [server]
  );

  const move = React.useCallback(
    route => {
      dispatch(push(route));
    },
    [dispatch]
  );
  const append = React.useCallback(
    route => {
      const path = [...location.pathname?.split('/').filter(x => x), route].join('/');
      move(`/${path}`);
    },
    [location, move]
  );

  useEffectOnce(() => {
    setState('loading');
    fetch().then(
      () => {
        setState('loaded');
      },
      () => {}
    );
  });

  const toggle = React.useCallback((itemKey, open = undefined) => {
    setMenu(m => {
      if (!m) return m;

      return produce<ExpandableMenuItem>(m, draft => {
        const index = draft.items.findIndex(x => x.elementId === itemKey);
        if (index < 0) {
          return m;
        }
        if (open !== undefined && open !== null) {
          // eslint-disable-next-line no-param-reassign
          draft.items[index].open = open;
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.items[index].open = !draft.items[index].open;
        }
        return draft;
      });
    });
  }, []);

  const reload = React.useCallback(() => {
    history.go(0);
  }, [history]);

  return React.useMemo(
    () => ({
      menu,
      flat,
      current,
      paths,
      fetch,
      move,
      append,
      toggle,
      reload,
      state,
    }),
    [menu, flat, current, paths, fetch, move, append, toggle, reload, state]
  );
}

export type NavigationContextType = ReturnType<typeof useNavContext>;

const NavigationContext = React.createContext<Partial<NavigationContextType>>({});

function NavigationProvider({ children }: React.PropsWithChildren<unknown>) {
  return (
    <NavigationContext.Provider value={useNavContext()}>{children}</NavigationContext.Provider>
  );
}

function useNavigation() {
  const context = React.useContext(NavigationContext);

  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }

  return context as NavigationContextType;
}

export { NavigationProvider, useNavigation };
