import React from 'react';
import { useField } from 'formik';
import { Checkbox, CheckboxProps, FormControl, FormControlLabel } from '@material-ui/core';

type FormikCheckboxProps<T> = CheckboxProps & {
  name: string;
  label: string;
  options: T[];
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end' | undefined;
  allowSelectAll?: boolean;
};

const FormikCheckbox = <T extends Record<string, unknown>>({
  id,
  name,
  label,
  onBlur,
  disabled,
}: { id?: string } & FormikCheckboxProps<T>) => {
  const [field, meta] = useField(name || id || '');

  return (
    <FormControl
      name={name}
      component="fieldset"
      error={!!(meta.touched && meta.error)}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={field.value}
            {...field}
            onBlur={(...args) => {
              if (onBlur) onBlur(...args);
              field.onBlur(...args);
            }}
            color="primary"
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default FormikCheckbox;
