import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    '& .MuiTypography-overline': {},
    '& .MuiGrid-item:not(:last-child)': {
      borderRight: '1px solid #efefef',
    },
    '& .MuiListItem-root': {
      textAlign: 'center',
    },
  },
}));
