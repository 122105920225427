import React from 'react';
import { SelectProps, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import useStyles from './SelectField.styles';

export type SelectFieldProps = SelectProps & {
  name: string;
  label?: string;
  options?: {
    label: string;
    value: string | number;
    disabled?: boolean;
  }[];
  withEmpty?: boolean;
  error?: boolean | null;
};

const SelectField = ({
  name,
  label,
  options = [],
  error,
  withEmpty = true,
  required,
  ...rest
}: SelectFieldProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root} variant="filled" error={error}>
      <InputLabel id={`${name}-label`}>
        {label}
        {required && ' *'}
      </InputLabel>
      <Select name={name} labelId={`${name}-label`} variant="filled" fullWidth {...rest}>
        {withEmpty && (
          <MenuItem value="" disabled>
            <em>Select one...</em>
          </MenuItem>
        )}
        {options.map(item => (
          <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
