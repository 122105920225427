import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { PlusCircle } from 'mdi-material-ui';

const NewItemButton = ({ text, ...rest }) => {
  return (
    <>
      <Button variant="contained" color="primary" startIcon={<PlusCircle />} {...rest}>
        {text}
      </Button>
    </>
  );
};

NewItemButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NewItemButton;
