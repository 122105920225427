/* eslint-disable no-param-reassign */
import ReportInput from 'modules/Shared/models/ReportInput';
import ReportDto from 'modules/Shared/models/ReportDto';
import { DepartmentsSelectList } from 'modules/Departments';
import UserSearchWidget from 'modules/Users/UserSearchWidget';

function noop<T>(id: T) {
  return id;
}

export const schema = noop;

export const uiSchema = ui => {
  ui['ui:order'] = ['title', '*'];

  ui.categories = {
    'ui:widget': 'hidden',
  };

  ui.reportType = {
    'ui:widget': 'select',
  };

  ui.owner = {
    'ui:field': UserSearchWidget,
    description: 'Search by name or email address',
  };

  ui.department = {
    'ui:widget': DepartmentsSelectList,
  };

  ui.ownerDepartment = {
    'ui:widget': DepartmentsSelectList,
  };

  return ui;
};

export const mapToEdit = () => (current: ReportDto): ReportInput => {
  return {
    ...current,
    owner: {
      identifier: current?.owner?.id,
      name: current?.owner?.name,
    },
  };
};

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
