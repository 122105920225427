import React, { useState } from 'react';
import { FieldArray, useField } from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core';

interface AddOption<T> {
  (options: T[]): T;
}

type FormikCheckboxGroupProps<T> = {
  name: string;
  label: string;
  options: T[];
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end' | undefined;
  allowSelectAll?: boolean;
  allowAdd?: boolean;
  maxOptions?: number;
  onAddOption?: AddOption<T>;
};

enum ToggleState {
  On,
  Off,
}

const FormikCheckboxGroup = <T extends Record<string, unknown>>({
  name,
  label,
  options,
  labelPlacement,
  allowSelectAll = false,
  allowAdd = false,
  maxOptions,
  onAddOption,
}: FormikCheckboxGroupProps<T>) => {
  const [field, meta, helper] = useField(name);
  const [allToggle, setAllToggle] = useState<ToggleState>(ToggleState.Off);
  const [opts, setOpts] = useState<T[]>(options);

  const toggleAll = () => {
    if (allToggle === ToggleState.Off) helper.setValue(options);
    else helper.setValue([]);

    setAllToggle(allToggle === ToggleState.Off ? ToggleState.On : ToggleState.Off);
  };

  const addOption = push => {
    if (!onAddOption) return;

    const newOption = onAddOption(opts);
    setOpts([...opts, newOption]);
    push(newOption);
  };

  const canAddOption = () => allowAdd && (!maxOptions || opts.length < maxOptions);

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <FormControl component="fieldset" error={!!(meta.touched && meta.error)}>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup aria-label="splts" row>
            {opts.map(item => (
              <FormControlLabel
                value={item}
                checked={field.value.includes(item)}
                onChange={e => {
                  const target = e.target as HTMLInputElement;
                  if (target.checked) push(item);
                  else remove(field.value.indexOf(item));
                }}
                control={<Checkbox color="primary" name={name} />}
                label={item}
                key={`item-${item}`}
                labelPlacement={labelPlacement}
              />
            ))}
          </FormGroup>
          {allowSelectAll && (
            <Button variant="contained" onClick={toggleAll}>
              {allToggle === ToggleState.Off ? 'Select' : 'Deselect'} All
            </Button>
          )}
          {canAddOption() && (
            <Button variant="contained" onClick={() => addOption(push)}>
              Add
            </Button>
          )}
        </FormControl>
      )}
    </FieldArray>
  );
};

export default FormikCheckboxGroup;
