import React from 'react';
import {
  FolderPlusOutline,
  NotePlusOutline,
  FolderEditOutline as EditIcon,
  FolderRemoveOutline as DeleteIcon,
} from 'mdi-material-ui';
import { useReportAdmin } from '@ldx-drp/Report/features/administration/useReportAdmin';
import { SpeedDialActionType } from '@ldx-dmp/SpeedDial/useSpeedDialContext';
import { CategoryDto } from '@ldx-dmp/Shared/models';

import { useCategoryAdmin } from './features/administration/useCategoryAdmin';

export function useCategoryActions(id: string | undefined, category?: CategoryDto) {
  const { onAddCategory, onEditCategory, onDeleteCategory } = useCategoryAdmin();
  const { onAddReport } = useReportAdmin();

  const actions = React.useMemo(() => {
    const list: SpeedDialActionType[] = [];
    list.push({
      icon: <NotePlusOutline />,
      name: 'Add Report',
      onClick: () => onAddReport(id),
      permissions: ['LDX.Data.Reporting.Reports.Modify'],
    });

    if (category?.parent === '00000000-0000-0000-0000-000000000000' || !category?.parent) {
      list.push({
        icon: <FolderPlusOutline />,
        name: 'Add Category',
        onClick: () => onAddCategory(id),
        permissions: ['LDX.Data.Reporting.Categories.Modify'],
      });
    }

    list.push({
      icon: <EditIcon />,
      name: 'Edit',
      onClick: () => onEditCategory(category),
      permissions: ['LDX.Data.Reporting.Categories.Modify'],
    });

    list.push({
      icon: <DeleteIcon color="error" />,
      name: 'Delete',
      onClick: () => onDeleteCategory(category),
      permissions: ['LDX.Data.Reporting.Categories.Modify'],
    });

    return list;
  }, [category, id, onAddCategory, onAddReport, onDeleteCategory, onEditCategory]);

  return React.useMemo(() => ({ actions }), [actions]);
}

export default useCategoryActions;
