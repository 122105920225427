/* eslint-disable no-param-reassign */
import CategoryInput from 'modules/Shared/models/CategoryInput';
import CategoryDto from 'modules/Shared/models/CategoryDto';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  return sch;
};

export const uiSchema = ui => {
  ui['ui:order'] = ['title', '*'];
  ui.parent = {
    'ui:widget': 'hidden',
  };
  ui.status = {
    'ui:widget': 'hidden',
  };
  return ui;
};

export const mapToEdit = () => (current: CategoryDto): CategoryInput => ({
  ...current,
  report: current?.report?.id,
});

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
