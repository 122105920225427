import { push } from 'connected-react-router';
import { USER_EXPIRED, USER_FOUND } from 'redux-oidc';
import {
  AppActionTypes,
  appLoadingActionCreators,
  navigationActionCreators,
  abpConfig,
} from 'modules/Core/actions';

export default [
  {
    type: USER_EXPIRED,
    handler: (_, dispatch, store) => () => {
      const state = store();
      const currenthPath = state.router.location.pathname;
      const ignorePaths = [
        '/auth/login',
        '/auth/callback',
        '/auth/logout',
        '/auth/logout/callback',
      ];

      if (!ignorePaths.includes(currenthPath))
        dispatch(push(`/auth/login?returnTo=${currenthPath}${state.router.location.search}`));
    },
  },
  {
    type: USER_FOUND,
    handler: (_, dispatch) => () => dispatch(appLoadingActionCreators.initiateAppLoad()),
  },
  {
    type: AppActionTypes.INITIATE_APP_LOAD,
    handler: (_, dispatch) => () => {
      dispatch(abpConfig.fetchAbpApplicationConfig());
    },
  },
  {
    type: abpConfig.FetchAbpApplicationConfig.SUCCESS,
    handler: (_, dispatch, store) => () => {
      const state = store();
      if (state.app.user.profile && state.app.user.permissions) {
        dispatch(navigationActionCreators.initialiseMainNavigation(state.app.user.permissions));
        dispatch(appLoadingActionCreators.appLoaded());
      }
    },
  },
];
