/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { CircularProgress, Snackbar, SnackbarProps } from '@material-ui/core';

const AutoSavingSnackbar = React.memo((props: SnackbarProps) => (
  <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} {...props}>
    <CircularProgress size={32} color="primary" data-testid="autosaving" />
  </Snackbar>
));

export default AutoSavingSnackbar;
