import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Spinner.styles';

export default () => {
  const classes = makeStyles(styles)();

  return (
    <div className={classes.flex}>
      <CircularProgress size={60} />
    </div>
  );
};
