import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import { rootPath } from './constants';

const routes = () => {
  return (
    <>
      <Switch>
        <Route path={rootPath}>
          <Home />
        </Route>
      </Switch>
    </>
  );
};

routes.path = rootPath;

export { entityPath } from './constants';
export default routes;
