import { getUserPermissions } from 'modules/Core/reducers/selectors';
import React from 'react';
import { useSelector } from 'react-redux';

export type SpeedDialActionType = {
  icon: React.ReactElement;
  name: string;
  onClick: () => void;
  permissions?: string[];
};

export default function useSpeedDialContext() {
  const userPerms = useSelector(getUserPermissions)?.map(x => x.name);

  const [allActions, setActions] = React.useState<SpeedDialActionType[]>([]);

  const resetActions = React.useCallback(() => {
    setActions([]);
  }, []);

  const actions = React.useMemo(
    () =>
      allActions.filter(action => {
        if (!action.permissions || !action.permissions.length) {
          return true;
        }

        if (action.permissions?.some(x => userPerms.includes(x))) {
          return true;
        }

        return false;
      }),
    [allActions, userPerms]
  );

  return React.useMemo(
    () => ({
      actions,
      setActions,
      visible: actions.length,
      resetActions,
    }),
    [actions, setActions, resetActions]
  );
}
