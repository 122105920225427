import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

export type FetchDataFunction = (tableState) => void;

export type DataTableProps = {
  fetchData: FetchDataFunction;
  isFetching: boolean;
  totalCount: number;
  columns?: Record<string, unknown>;
  data?: Record<string, unknown>;
  options?: Record<string, unknown>;
  showData?: boolean;
};

function DataTable({
  fetchData,
  isFetching,
  totalCount,
  columns,
  data,
  options = {},
  showData = true,
}: DataTableProps) {
  const [table, setTableState] = React.useState(null);

  const fetchDataHandler = React.useCallback(
    tableState => {
      fetchData(tableState);
      setTableState(tableState);
    },
    [fetchData]
  );

  const handleFilterSubmit = React.useCallback(applyNewFilters => {
    applyNewFilters();
  }, []);

  const onFilterConfirm = React.useCallback(
    filterList => {
      fetchDataHandler({
        ...(table || {}),
        filterList,
      });
    },
    [fetchDataHandler, table]
  );

  const serverOptions = React.useMemo(() => {
    return {
      serverSide: true,
      confirmFilters: true,
      count: totalCount,
      onFilterConfirm,
      onFilterChipClose: (_x, _y, filterList) => onFilterConfirm(filterList),
      customFilterDialogFooter: (_currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: '40px' }}>
            <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
              Apply Filters
            </Button>
          </div>
        );
      },
      onTableInit: (_action, tableState) => {
        if (!table) fetchDataHandler(tableState);
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
          case 'sort':
          case 'changeRowsPerPage':
          case 'search':
            fetchDataHandler(tableState);
            break;
          default:
            // eslint-disable-next-line no-console
            console.info('action not handled.', action);
        }
      },
    };
  }, [fetchDataHandler, handleFilterSubmit, onFilterConfirm, table, totalCount]);

  return (
    <>
      {!showData && (
        <CircularProgress
          data-testid="loading"
          size={24}
          style={{ marginLeft: 15, position: 'relative', top: 4, float: 'left' }}
        />
      )}
      {showData && (
        <div style={{ width: '100%' }}>
          <div style={{ position: 'relative', visibility: isFetching ? 'visible' : 'hidden' }}>
            <CircularProgress
              data-testid="loading"
              size={24}
              style={{ marginLeft: 15, position: 'absolute', top: 4, float: 'left' }}
            />
          </div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={{
              ...options,
              ...serverOptions,
            }}
          />
        </div>
      )}
    </>
  );
}

export default DataTable;
