// eslint-disable-next-line import/prefer-default-export
export enum DialogState {
  Open = 'Open',
  Closed = 'Closed',
}

export enum EditingState {
  Editing = 'Editing',
  NotEditing = 'NotEditing',
}

export interface ApiError {
  code?: string | null;
  message: string;
  details?: string | null;
  validationErrors?: ValidationError[];
}

export interface ValidationError {
  message: string;
  members: string[];
}

export interface DialogDefinition {
  title: string;
  dialogName: string;
}

export type BreakpointStep = boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
