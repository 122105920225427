import React from 'react';
import {
  ClipboardListOutline,
  FlaskOutline,
  FormatListChecks,
  ProgressCheck,
  Stove,
  Tools,
  WashingMachine,
} from 'mdi-material-ui';

export default {
  flask: <FlaskOutline />,
  washingMachine: <WashingMachine />,
  clipboardList: <ClipboardListOutline />,
  tools: <Tools />,
  stove: <Stove />,
  formatListChecks: <FormatListChecks />,
  progressCheck: <ProgressCheck />,
};
