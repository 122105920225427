import React from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';
import { CategoryDto } from 'modules/Shared/models';

export function useServer() {
  const config = useSelector(getConfig) as AppConfig;
  const apiUrl = config.api.base_uri;

  return React.useMemo(
    () => ({
      getById: id => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios.get<CategoryDto>(`${apiUrl}/categories/${id}`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      postCategory: data => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios.post<CategoryDto>(`${apiUrl}/categories`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchCategory: (id, data) => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios.patch<CategoryDto>(`${apiUrl}/categories/${id}`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      deleteCategory: id => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios
            .request<CategoryDto>({
              url: `${apiUrl}/categories/${id}`,
              method: 'DELETE',
              data: { id },
            })
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        });
      },
      postPermission: (id, data) => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios.post<CategoryDto>(`${apiUrl}/categories/${id}/permission`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchPermission: (id, data) => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios.patch<CategoryDto>(`${apiUrl}/categories/${id}/permission`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      deletePermission: (id, data) => {
        return new Promise<AxiosResponse<CategoryDto>>((resolve, reject) => {
          axios
            .delete<CategoryDto>(`${apiUrl}/categories/${id}/permission`, { data })
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        });
      },
    }),
    [apiUrl]
  );
}

export default useServer;
