import moment from 'moment';

export const formatDate = (date?: string, placeholder = '-'): string => {
  return date ? moment(date).format('MMMM Do YYYY, HH:mm') : placeholder;
};

export const formatShortDate = (date?: string, placeholder = '-'): string => {
  return date ? moment(date).format('D MMM YYYY') : placeholder;
};

export const formatShortDateTime = (date?: string, placeholder = '-'): string => {
  return date ? moment(date).format('D MMM YYYY, HH:mm') : placeholder;
};

export const formatTime = (date?: string, placeholder = '-'): string => {
  return date ? moment(date).format('HH:mm') : placeholder;
};

export { SnackbarUtilsConfigurator, default as SnackbarUtils } from './SnackbarUtils';
