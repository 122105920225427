import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  radioGroup: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  actionButtons: {
    flex: '0 0 auto',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > :not(:first-child)': {
      marginLeft: '8px',
    },
  },
}));

export default useStyles;
