import { applyMiddleware, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware, { Saga } from 'redux-saga';

import { SagaTask } from '@ldx-dmp/Redux';
import { interceptorMiddleware, apiBaseUrlMiddleware } from '@ldx-dmp/Core/middleware';
import apiAuthInjector from '@ldx-dmp/Auth/middleware/authApiInjector';
import { AppConfig } from '@ldx-dmp/Core/types';

import createRootReducer from './rootReducer';

// SEE: https://github.com/supasate/connected-react-router/issues/312#issuecomment-748984685
export const history = {
  action: {},
  location: {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(createBrowserHistory() as any),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function configureStore(appConfig: AppConfig, additionalState: any = {}) {
  const sagaMiddleware = createSagaMiddleware();

  const initialAppState = {
    app: {
      user: {
        profile: null,
        permissions: null,
      },
      config: appConfig,
      loading: false,
      error: false,
      mainNavigationItems: [],
      isSidebarOpen: true,
    },
    ...additionalState,
  };

  const store = {
    ...createStore(
      createRootReducer(history),
      initialAppState,
      composeWithDevTools(
        applyMiddleware(
          routerMiddleware(history),
          thunk,
          sagaMiddleware,
          apiBaseUrlMiddleware,
          apiAuthInjector,
          apiMiddleware,
          interceptorMiddleware
        )
      )
    ),

    injectedSagas: {},
    injectedReducers: {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    runSaga: (saga: Saga, ...args: any[]) => sagaMiddleware.run(saga, ...args) as SagaTask,
  };

  return store;
}
