import { Theme } from '@material-ui/core';

/* eslint-disable @typescript-eslint/restrict-plus-operands */
const sidebarWidth = '240px';
const getWidth = isSidebarOpen => (isSidebarOpen ? `calc(100% - ${sidebarWidth})` : null);
const getMargin = isSidebarOpen => (isSidebarOpen ? sidebarWidth : null);

export default (theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    width: props => getWidth(props.isSidebarOpen),
    marginLeft: props => getMargin(props.isSidebarOpen),
    transition: theme.transitions.create(['width', 'margin']),
  },
  grow: {
    flexGrow: 1,
  },
  toolbarLogo: {
    width: theme.spacing(6) + 1,
    height: theme.spacing(6) + 1,
    marginLeft: '-10px',
  },
  toolbarLogoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  accountButton: {
    color: '#FFF',
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '60ch',
      },
    },
  },
});
