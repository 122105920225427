import { ExtensibleAuditedEntityDto } from './ExtensibleAuditedEntityDto';

export class ExtensibleFullAuditedEntityDto<T = string> extends ExtensibleAuditedEntityDto<T> {
  isDeleted?: string;

  deleterId?: string;

  deletionTime?: string;

  constructor(initialValues: Partial<ExtensibleFullAuditedEntityDto<T>> = {}) {
    super(initialValues);
  }
}

export default ExtensibleFullAuditedEntityDto;
