import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '../Breadcrumbs';
import styles from './MainContent.styles';

const MainContent = ({ children }: React.PropsWithChildren<unknown>) => {
  const classes = makeStyles(styles)();

  return (
    <>
      <main className={classes.content}>
        <Breadcrumbs />
        {children}
      </main>
    </>
  );
};

export default MainContent;
