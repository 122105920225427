export const rootPath = '/departments';
export const entityPath = '/departments/:id';

export const addTitle = 'Add Department';
export const editTitle = 'Editing Department';
export const listTitle = 'Departments';
export const addButtonText = 'Add Department';
export const i18Namespace = 'Department';
export const defaultSort = 'title';
export const supportsDelete = false;
