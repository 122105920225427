/* eslint-disable no-underscore-dangle */
import { AppConfig } from 'modules/Core/types';

const environment = window._env_?.ENVIRONMENT || 'Local';
const uiBaseUri = window._env_?.UI_BASE_URI || 'http://localhost:3000';
const apiBaseUri = window._env_?.API_BASE_URI || 'http://localhost:44320';
const oidcBaseUri = window._env_?.OIDC_BASE_URI || 'http://localhost:44325';
const oidcLoggingEnabled = window._env_?.ENABLE_OIDC_LOGGING === 'true';
const oidcLogLevel = Number(window._env_?.OIDC_LOGGING_LEVEL || 2);
const cdnBaseUri = window._env_?.CDN_BASE_URI || 'https://d1572pqwbcrus4.cloudfront.net';
const version = window._env_?.VERSION || 'local';
const apmServiceName = window._env_?.APM_SERVICE_NAME || 'DRP UI';
const apmServerUrl = window._env_?.APM_SERVICE_URL || 'http://localhost:8200';
const apmEnv = window._env_?.APM_ENV || 'local';
const apmEnabled = window._env_?.APM_ENABLED === 'true' || false;

const config: AppConfig = {
  app: {
    base_uri: uiBaseUri,
    cdn_base_uri: cdnBaseUri,
    environment,
    version,
  },
  logging: {
    oidc: {
      enabled: oidcLoggingEnabled,
      level: oidcLogLevel,
    },
  },
  api: {
    base_uri: apiBaseUri,
    oidc_base_uri: oidcBaseUri,
  },
  oidc: {
    client_id: 'LDX_Reporting_UI',
    authority: oidcBaseUri,
    redirect_uri: `${uiBaseUri}/auth/callback`,
    silent_redirect_uri: `${uiBaseUri}/silent-renew`,
    post_logout_redirect_uri: `${uiBaseUri}/auth/logout/callback`,
    response_type: 'code',
    scope: 'ldx.reporting.api ldx.data.auth openid profile',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
  },
  apm: {
    serviceName: apmServiceName,
    serverUrl: apmServerUrl,
    serviceVersion: '',
    environment: apmEnv,
    enabled: apmEnabled,
  },
};

export default config;
