import { RSAA } from 'redux-api-middleware';

const apiAuthInjector = store => next => action => {
  const callApi = action[RSAA];
  const { user } = store.getState().oidc;

  if (callApi) {
    callApi.headers = {
      ...callApi.headers,
      Authorization: user ? `Bearer ${user.access_token}` : '',
    };
  }

  return next(action);
};

export default apiAuthInjector;
