/* istanbul ignore file */
import { useEffectOnce } from 'react-use';
import { useStore } from 'react-redux';
import { useSagaInjector } from './sagaInjector';
import { InjectableStore, SagaDescriptor } from '../storeTypes';

export function useSaga(reg: SagaDescriptor, props?: unknown) {
  const store = useStore();
  const injectSaga = useSagaInjector(store as InjectableStore);

  useEffectOnce(() => {
    injectSaga.inject(reg.key, reg, props);

    return function cleanup() {
      injectSaga.eject(reg.key);
    };
  });
}

export default useSaga;
