import * as yup from 'yup';

export default function validateChemistryOther(
  this: yup.StringSchema,
  ref,
  expected = 'Other',
  msg
): yup.StringSchema {
  return this.test({
    name: 'validateChemistryOther',
    exclusive: false,
    message: msg || 'Must supply a value for Chemistry Other',
    params: {
      reference: ref.path,
    },
    test(value) {
      if (this.resolve(ref) !== expected) return true;

      return !!value;
    },
  });
}
