import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Skeleton } from '@material-ui/lab';
import { LinkProps, ListSubheader } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { CategoryDto, ReportDto } from '@ldx-drp/Shared/models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    subHeading: {
      backgroundColor: theme.palette.background.paper,
    },
  })
);
interface ListItemLinkProps extends LinkProps {
  to?: string;
  primary?: string;
}
function ListItemLink(props: Omit<ListItemLinkProps, 'ref'>) {
  const { to, primary, ...other } = props;

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export default function CategoryReportsList({
  category,
  reports,
  title = 'Reports',
  ariaLabel,
  showEmpty = false,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any> & {
  reports?: ReportDto[];
  category?: CategoryDto;
  title?: string | React.ReactNode;
  ariaLabel?: string;
  showEmpty?: boolean;
}) {
  const classes = useStyles();

  const heading = <ListSubheader className={classes.subHeading}>{title}</ListSubheader>;

  if (!category || !reports) {
    return (
      <div className={classes.root}>
        <List component="nav" aria-label="main mailbox folders" subheader={heading}>
          {[1, 2, 3, 4, 5].map(i => (
            <ListItem key={i}>
              <Skeleton width="100%" variant="rect" height={30} />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }

  if (!reports?.length && !showEmpty) return <></>;

  if (!reports?.length && showEmpty)
    return (
      <div className={classes.root}>
        <List component="nav" aria-label={ariaLabel} subheader={heading} />
      </div>
    );

  return (
    <div className={classes.root}>
      <List component="nav" aria-label={ariaLabel} subheader={heading}>
        {reports?.map(r => (
          <ListItemLink key={r.id || r.url} primary={r.title} to={r.url} />
        ))}
      </List>
    </div>
  );
}
