import React from 'react';
import { Switch } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { AuthRouter } from 'modules/Auth/';
import Home from '@ldx-dmp/Home/routes';
import Activity from '@ldx-dmp/Activity/routes';
import Category from '@ldx-dmp/Category/routes';
import Report from '@ldx-dmp/Report/routes';
import Departments from '@ldx-dmp/Departments/routes';
import ReportTypes from '@ldx-dmp/ReportTypes/routes';
import { SignedInLayout } from '@ldx-drp/Layouts';
import { AppLoader } from '@ldx-dmp/Core';
import { LocationTracker } from 'modules/Analytics';

const AppRouter = () => (
  <Switch>
    {/* <Route path="/error" component={ErrorPage} /> */}
    <ApmRoute path="/auth" component={AuthRouter} />
    <ApmRoute
      path="/"
      component={() => (
        <AppLoader loading={false}>
          <SignedInLayout>
            <LocationTracker />
            <ApmRoute exact path="/" component={Home} />
            <ApmRoute path="/" component={Category} />
            <ApmRoute path="/" component={Report} />
            <ApmRoute path="/" component={Departments} />
            <ApmRoute path="/" component={Activity} />
            <ApmRoute path="/" component={ReportTypes} />
          </SignedInLayout>
        </AppLoader>
      )}
    />
  </Switch>
);

export default AppRouter;
