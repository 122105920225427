import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import useSpeedDial from './useSpeedDial';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      margin: 50,
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'fixed',
      bottom: 0,
      right: 0,
    },
  })
);

export default function OpenIconSpeedDial() {
  const classes = useStyles();
  const { actions, visible } = useSpeedDial();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="Actions"
        className={classes.speedDial}
        hidden={!visible}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        onClick={handleOpen}
      >
        {actions?.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              handleClose();
              action.onClick();
            }}
          />
        ))}
      </SpeedDial>
    </div>
  );
}
