import { ClearApiErrorAction } from '../actions/apiErrors';

export default (state = {}, action) => {
  const { type } = action;

  if (type === ClearApiErrorAction)
    return {
      ...state,
      [action.name]: null,
    };

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'REQUEST' ? null : action.payload.response.error,
  };
};
