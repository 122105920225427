import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0095d3',
      dark: '#00497b',
    },
    secondary: {
      main: '#1cb8dc',
    },
    background: {
      default: '#f0f9fb;',
    },
  },
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
      },
    },
    MuiTypography: {
      h1: { fontFamily: 'ITCAvantGardeStd-Bk' },
      h2: { fontFamily: 'ITCAvantGardeStd-Bk' },
      h3: { fontFamily: 'ITCAvantGardeStd-Bk' },
      h4: { fontFamily: 'ITCAvantGardeStd-Bk' },
      h5: { fontFamily: 'ITCAvantGardeStd-Bk' },
      h6: { fontFamily: 'ITCAvantGardeStd-Bk' },
      subtitle1: { fontFamily: 'ITCAvantGardeStd-Bk' },
      subtitle2: { fontFamily: 'ITCAvantGardeStd-Bk' },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
        backgroundColor: '#fff',
        fontFamily: 'ITCAvantGardeStd-Bk',
      },
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
    MuiTableRow: {
      root: {
        '&:hover:not(.MuiTableRow-footer)': {
          backgroundColor: '#bde3f2',
        },
        '&:nth-of-type(odd):not(.MuiTableRow-footer)': {
          backgroundColor: '#f0f9fb;',
          '&:hover': {
            backgroundColor: '#bde3f2',
          },
        },
      },
    },
    MUIDataTableToolbar: {
      filterPaper: {
        minWidth: '35%',
      },
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
});

export default theme;
