import axios, { AxiosResponse } from 'axios';

import AddFavouriteInput from 'modules/Favourites/models/AddFavouriteInput';

export default function postAsync(apiUrl: string) {
  return function api<TResponse>(
    event: AddFavouriteInput | undefined | null
  ): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/favourites`,
          method: 'POST',
          data: event,
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
