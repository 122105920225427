import React from 'react';
import { ReportDto } from 'modules/Shared/models';
import { useServer } from './useServer';

function useReportSearch(limit = 3) {
  const server = useServer();

  const [state, setState] = React.useState('idle');
  const [hits, setHits] = React.useState<ReportDto[]>([]);

  const refine = React.useCallback(
    async function search(query) {
      if (query?.length < limit) return;

      setState('searching');
      const response = await server.getSearch(query);
      setHits(response.data?.items || []);
      setState('idle');
    },
    [limit, server]
  );

  return React.useMemo(
    () => ({
      refine,
      loading: state === 'searching',
      hits,
      state,
      clear: () => {
        setHits(() => []);
      },
    }),
    [hits, refine, state]
  );
}

export default useReportSearch;
