import React from 'react';
import { Field } from 'formik';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';
import useStyles from './FormikRadioGroup.styles';

type RadioOption = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

export type FormikRadioGroupProps = RadioGroupProps & {
  name: string;
  label: string;
  options: RadioOption[];
};

const FormikRadioGroup = ({ name, label, options }: FormikRadioGroupProps) => {
  const classes = useStyles();

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <FormControl
            className={classes.root}
            component="fieldset"
            error={meta.touched && meta.error}
          >
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup row {...field} value={field.value?.toString()}>
              {options.map(opt => (
                <FormControlLabel
                  key={opt.value}
                  value={opt.value?.toString()}
                  control={<Radio color="primary" />}
                  label={opt.label}
                  labelPlacement="end"
                />
              ))}
            </RadioGroup>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default FormikRadioGroup;
