export default theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& .MuiTypography-h5': {
      lineHeight: '1.8',
    },
  },
  subtitle: {
    marginLeft: theme.spacing(1),
  },
});
