import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingScreen } from 'modules/Common';
import { selectors } from 'modules/Core/reducers';
import 'modules/Common/assets/fonts/fonts.css';

const AppLoadingManager = ({ loading, children }) => {
  if (loading) return <LoadingScreen message="Loading application..." />;
  return <>{children}</>;
};

const mapStateToProps = state => ({
  loading: !selectors.isAppLoaded(state),
});

AppLoadingManager.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default connect(mapStateToProps)(AppLoadingManager);
