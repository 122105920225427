import React from 'react';
import { WidgetProps, FieldProps } from '@rjsf/core';
import { useEffectOnce } from 'react-use';
import { PagedResultDto } from 'modules/Core';
import { DepartmentDto } from '../models';
import useServer from '../useServer';

export default ({ registry, uiSchema, ...props }: WidgetProps & FieldProps) => {
  const [values, setValues] = React.useState<{ value: string | number; label: string }[]>([]);
  const [state, setState] = React.useState('idle');
  const server = useServer();
  const { SelectWidget } = registry.widgets;

  useEffectOnce(() => {
    setState('loading');
    server.getListAsync<PagedResultDto<DepartmentDto>>(0, 1000, 'title').then(
      response => {
        setValues(
          response?.data?.items?.map(x => ({
            value: String(x.id),
            label: String(x.title),
          })) || []
        );
        setState('idle');
      },
      () => {
        setState('idle');
      }
    );
  });

  return (
    <SelectWidget
      {...props}
      uiSchema={{
        ...uiSchema,
        loading: state !== 'idle',
      }}
      registry={registry}
      options={{
        enumOptions: values || [],
      }}
    />
  );
};
