export default theme => ({
  root: {
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '0.9rem',
    },
  },
});
