/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserId } from 'modules/Core/reducers/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { UserProfileDto } from './models';
import { setProfile } from './userProfileSlice';
import useServer from './useServer';

export default () => {
  const dispatch = useDispatch();
  const server = useServer();
  const userId = useSelector(getUserId);

  useEffectOnce(() => {
    server.getAsync<UserProfileDto>(userId).then(
      r => dispatch(setProfile(r.data)),
      () => {}
    );
  });
};
