/* istanbul ignore file */

import CreationAuditedAggregateRoot from './CreationAuditedAggregateRoot';

export default class AuditedAggregateRoot<T> extends CreationAuditedAggregateRoot<T> {
  lastModificationTime?: string;

  lastModifierId?: string;

  constructor(initialValues: Partial<AuditedAggregateRoot<T>> = {}) {
    super(initialValues);
    this.lastModificationTime = initialValues.lastModificationTime;
    this.lastModifierId = initialValues.lastModifierId;
  }
}
