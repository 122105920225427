/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { useUserPreferences } from 'modules/User';
import { getAppSettings, getConfig } from 'modules/Core/reducers/selectors';

const VersionInfo = () => {
  const {
    state: { showVersion },
  } = useUserPreferences();
  const { ApiVersion, Environment } = useSelector(getAppSettings);
  const {
    app: { version },
  } = useSelector(getConfig);

  return React.useMemo(() => {
    if (!showVersion) {
      return <></>;
    }

    return (
      <Typography variant="caption">
        {Environment} ui: {version} api: {ApiVersion}
      </Typography>
    );
  }, [ApiVersion, Environment, showVersion, version]);
};

export default VersionInfo;
