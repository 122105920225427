import React from 'react';
import { useEffectOnce } from 'react-use';
import { LoadingScreen } from 'modules/Common';
import { useUserPreferences, defaultState } from 'modules/User';
import authManager from '../authManager';

const LogoutPage = () => {
  const userManager = authManager.getUserManager();
  const { dispatch } = useUserPreferences();

  useEffectOnce(() => {
    dispatch({
      type: 'update',
      payload: defaultState,
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    userManager.signoutRedirect();
  });

  return <LoadingScreen message="Signing out..." />;
};

export default LogoutPage;
