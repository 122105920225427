import React from 'react';

import { JsonFormProps } from './JsonFormProps';
import { useJsonForms } from './useJsonForms';

import ObjectField from './ObjectFieldTemplate';
import TemplateField from './FieldTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NormalFormProps<T = any> = {
  initialValues: T;
  onClose: () => void;
  isSubmitting?: boolean;
  isValid?: boolean;
  showErrorList?: boolean;
  topButtons?: boolean;
} & Omit<JsonFormProps<T>, 'component'>;

export function NormalForm<T>({
  title,
  schema,
  uiSchema,
  initialValues,
  onSubmit,
  onClose,
  isSubmitting,
  disabled,
  validation,
  showErrorList = false,
  onSave,
  fieldTemplate = TemplateField,
  ...props
}: NormalFormProps<T>) {
  const { JsonForm } = useJsonForms<T>();

  return (
    <>
      <JsonForm
        title={title}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        formData={initialValues}
        schema={schema}
        uiSchema={uiSchema}
        ObjectFieldTemplate={ObjectField}
        FieldTemplate={fieldTemplate}
        widgets={{}}
        showErrorList={showErrorList}
        validationSchema={validation}
        onSave={onSave}
        {...props}
      />
    </>
  );
}

export default NormalForm;
