const getWidth = (theme, isOpen) => (isOpen ? '240px' : theme.spacing(7) + 1);
const transition = theme =>
  theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  });

export default theme => ({
  root: {
    flexShrink: 0,
    transition: transition(theme),
    whiteSpace: 'nowrap',
    '& .MuiPaper-root': {
      transition: transition(theme),
      backgroundColor: theme.palette.primary.dark,
      '& *': {
        color: '#FFF',
      },
    },
  },
  open: {
    width: props => getWidth(theme, props.isOpen),
    '& .MuiPaper-root': {
      width: props => getWidth(theme, props.isOpen),
    },
  },
  closed: {
    width: props => getWidth(theme, props.isOpen),
    '& .MuiPaper-root': {
      overflowX: 'hidden',
      width: props => getWidth(theme, props.isOpen),
    },
  },
});
