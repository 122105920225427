/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import produce from 'immer';
import { useNavigation } from '@ldx-dmp/Menu';
import { SnackbarUtils } from '@ldx-dmp/Common';
import { EditorType } from '@ldx-dmp/Administration/Container';
import * as createForm from '../create/categoryInputFormSchema';
import { useServer } from '../../useServer';

export function useCategoryAdminContext() {
  const server = useServer();
  const { fetch, append, move, reload } = useNavigation();

  const [editorState, setEditorState] = React.useState<EditorType>({ state: 'idle' });

  const onAddCategory = React.useCallback((parent = null) => {
    setEditorState(s => ({
      ...s,
      state: 'adding',
      parent,
      title: 'Add Category',
      form: produce(createForm, (draft: any) => {
        draft.uiSchema.report = { 'ui:widget': 'hidden' };
      }),
      initialValues: {
        parent,
      },
    }));
  }, []);

  const onEditCategory = React.useCallback((initialValues: any) => {
    setEditorState(s => ({
      ...s,
      state: 'editing',
      parent: initialValues.parent,
      title: 'Edit Category',
      form: produce(createForm, (draft: any) => {
        draft.schema.properties.report.enum = initialValues?.reports?.map(x => x.id);
        draft.schema.properties.report.enumNames = initialValues?.reports?.map(x => x.title);
      }),
      initialValues: createForm.mapToEdit(initialValues),
    }));
  }, []);

  const onDeleteCategory = React.useCallback(
    function onDeleteCategory({ id }) {
      return new Promise<void>((resolve, reject) => {
        setEditorState(s => ({
          ...s,
          state: 'deleting',
          parent: null,
          title: 'Delete Category',
          initialValues: { id },
          onSubmit: async function a(data: any) {
            try {
              const response = await server.deleteCategory(data.id);

              if (response.status >= 400) {
                SnackbarUtils.error('Category could not be deleted');
              } else {
                move('/');
                SnackbarUtils.success('Category deleted');
              }

              setEditorState(ns => ({
                ...ns,
                state: 'idle',
              }));

              resolve();
            } catch (error) {
              reject(error);
            }
          },
        }));
      });
    },
    [move, server]
  );

  const closeEditor = React.useCallback(() => {
    setEditorState(s => ({
      ...s,
      state: 'idle',
    }));
  }, []);

  const exitEditor = React.useCallback(() => {
    setEditorState(() => ({
      state: 'idle',
    }));
  }, []);

  const submitEditor = React.useCallback(
    async function submit(data) {
      if (editorState.onSubmit) {
        await editorState.onSubmit(data);
        return;
      }
      const bodyFormData = new FormData();

      Object.keys(data).forEach(key => {
        if (data[key]) {
          bodyFormData.append(key, data[key]);
        }
      });

      const request =
        editorState.state === 'adding'
          ? server.postCategory(bodyFormData)
          : server.patchCategory(data.id, bodyFormData);

      const response = await request;

      await fetch();

      setEditorState(s => ({
        ...s,
        state: 'idle',
      }));

      if (editorState.state === 'adding') {
        append(response.data.url);
      } else {
        reload();
      }

      SnackbarUtils.success('Category saved');
    },
    [editorState, server, fetch, append, reload]
  );

  return React.useMemo(
    () => ({
      onAddCategory,
      editorState,
      setEditorState,
      closeEditor,
      submitEditor,
      exitEditor,
      onEditCategory,
      onDeleteCategory,
    }),
    [
      onAddCategory,
      editorState,
      closeEditor,
      submitEditor,
      exitEditor,
      onEditCategory,
      onDeleteCategory,
    ]
  );
}

export default useCategoryAdminContext;
