import { EditingState } from 'modules/Common/types';

export default (state = {}, action) => {
  const { type } = action;
  const matches = /(.*_EDIT)_(STARTED|STOPPED)/.exec(type);

  if (!matches) return state;

  const [, name, editingState] = matches;
  return {
    ...state,
    [name]: {
      state: editingState === 'STARTED' ? EditingState.Editing : EditingState.NotEditing,
      itemId: action.payload,
    },
  };
};
