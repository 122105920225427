import React from 'react';
import useAnalytics from 'modules/Analytics/useAnalytics';
import { useLocation } from 'react-use';

export default function LocationTracker() {
  const location = useLocation();
  const { track } = useAnalytics({
    auto: false,
    defaults: {
      action: 'MovedLocation',
    },
  });

  React.useEffect(() => {
    // track({
    //   message: location.pathname,
    // });
  }, [location.pathname, track]);

  return <></>;
}
