import { createUserManager } from 'redux-oidc';
import { UserManager, UserManagerSettings } from 'oidc-client';

let userManager: UserManager;

export default {
  init(options: UserManagerSettings) {
    userManager = createUserManager(options);
  },

  getUserManager(): UserManager {
    return userManager;
  },
};
