import React from 'react';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from '@ldx-dmp/Common';
import { FlexWrapper, MainContent, Sidebar, Topbar } from '@ldx-dmp/Core/components';
import { NavigationProvider } from '@ldx-dmp/Menu';
import { SpeedDial, useSpeedDialContext, SpeedDialContext } from '@ldx-drp/SpeedDial';
import { CategoryAdministration } from 'modules/Category';
import { ReportAdministration } from 'modules/Report';
import useUserProfile from 'modules/UserProfile/useUserProfile';

const SignedInLayout = ({ children }: React.PropsWithChildren<unknown>) => {
  useUserProfile();

  return (
    <>
      <NavigationProvider>
        <SpeedDialContext.Provider value={useSpeedDialContext()}>
          <CategoryAdministration>
            <ReportAdministration>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <SnackbarUtilsConfigurator />
                <FlexWrapper>
                  <Sidebar />
                  <Topbar />
                  <MainContent>
                    {children}
                    <SpeedDial />
                  </MainContent>
                </FlexWrapper>
              </SnackbarProvider>
            </ReportAdministration>
          </CategoryAdministration>
        </SpeedDialContext.Provider>
      </NavigationProvider>
    </>
  );
};

export default SignedInLayout;
