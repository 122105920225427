/* istanbul ignore file */
import Entity from './Entity';

export default class AggregateRoot<T> extends Entity {
  extraProperties?: T[];

  concurrencyStamp?: T;

  constructor(initialValues: Partial<AggregateRoot<T>> = {}) {
    super(initialValues);
    this.extraProperties = initialValues.extraProperties;
    this.concurrencyStamp = initialValues.concurrencyStamp;
  }
}
