import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigation } from 'modules/Menu';

export default () => {
  const { paths } = useNavigation();

  return React.useMemo(() => {
    const routes = [...paths];
    routes.pop();

    return (
      <Breadcrumbs aria-label="breadcrumb">
        {(paths.length > 0 && (
          <Link color="inherit" component={RouterLink} to="/">
            Home
          </Link>
        )) || <Typography color="textPrimary">&nbsp;</Typography>}
        {routes?.map(path => (
          <Link color="inherit" key={path.url} component={RouterLink} to={path.url}>
            {path.displayName}
          </Link>
        ))}
      </Breadcrumbs>
    );
  }, [paths]);
};
