/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Form from './Form';

const useStyles = makeStyles((theme: Theme) => {
  return {
    bottomPush: {
      position: 'fixed',
      bottom: 0,
      textAlign: 'right',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  };
});

export function DrawerForm<T>({
  open,
  onFormClose = () => {},
  onFormExit = () => {},
  form,
  onFormSubmit,
  title,
  buttonText = 'Save',
  initialValues = {},
  anchor,
  className,
  ...props
}: {
  open: boolean;
  onFormClose?: () => void;
  onFormExit?: () => void;
  form: any;
  onFormSubmit: (data: T) => Promise<unknown>;
  title?: string;
  buttonText?: string;
  initialValues: any;
  anchor?: any;
} & Record<string, any>) {
  const classes = useStyles();
  const [state, toggle] = React.useState<'opened' | 'closed' | 'closing'>('closed');

  // const handleExit = () => {
  //   toggle('closed');
  //   onFormExit();
  // };

  // const handleClose = React.useCallback(() => {
  //   toggle('closing');
  //   onFormClose();
  //   handleExit()
  // }, [onFormClose]);

  const { handleClose } = React.useMemo(
    () => ({
      handleClose: () => {
        toggle('closing');
        onFormClose();
        toggle('closed');
        onFormExit();
      },
    }),
    [onFormClose, onFormExit]
  );

  const handleSubmit = async data => {
    await onFormSubmit(data);
  };

  React.useEffect(() => {
    if (open && state === 'closed') {
      toggle('opened');
    }

    if (!open && state === 'opened') {
      handleClose();
    }
  }, [state, open, toggle, handleClose]);

  return (
    <Drawer open={state === 'opened'} onClose={handleClose} anchor={anchor}>
      <div className={className}>
        <Form
          {...form}
          {...props}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onClose={handleClose}
        >
          {({ onSubmit, isSubmitting, content }) => (
            <>
              {title && <DialogTitle style={{ paddingBottom: 0 }}>{title}</DialogTitle>}
              <DialogContent style={{ paddingTop: 0 }}>{content}</DialogContent>
              <DialogActions className={classes.bottomPush}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
                  {buttonText}
                </Button>
                <Button onClick={handleClose} variant="contained" disabled={isSubmitting}>
                  Cancel
                </Button>
              </DialogActions>
            </>
          )}
        </Form>
      </div>
    </Drawer>
  );
}

export default DrawerForm;
