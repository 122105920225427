/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { utils } from '@rjsf/core';

const { retrieveSchema, isObject } = utils;

export function toIdSchema(schema, id, rootSchema, formData = {}, idPrefix = '') {
  const idSchema = {
    $id: id || idPrefix,
  };
  if ('$ref' in schema || 'dependencies' in schema || 'allOf' in schema) {
    const retrievedSchema = retrieveSchema(schema, rootSchema, formData);
    return toIdSchema(retrievedSchema, id, rootSchema, formData || {}, idPrefix);
  }
  if ('items' in schema && !schema.items.$ref) {
    return toIdSchema(schema.items, id, rootSchema, formData || {}, idPrefix);
  }
  if (schema.type !== 'object') {
    return idSchema;
  }
  for (const name in schema.properties || {}) {
    const field = schema.properties[name];
    const fieldId = idPrefix + name;
    idSchema[name] = toIdSchema(
      isObject(field) ? field : {},
      fieldId,
      rootSchema,
      // It's possible that formData is not an object -- this can happen if an
      // array item has just been added, but not populated with data yet
      (formData || {})[name],
      `${fieldId}.`
    );
  }
  return idSchema;
}

export default {};
