import React from 'react';

import { WidgetProps } from '@rjsf/core';
import { SelectFieldProps } from '@ldx-dmp/Common/components/SelectField';
import FormikSelectField from '@ldx-dmp/Common/components/FormikSelectField';

export type SelectWidgetProps = WidgetProps & SelectFieldProps;

export const SelectWidget = ({
  schema,
  id,
  options,
  label,
  multiple,
  required,
  uiSchema,
}: SelectWidgetProps) => {
  // eslint-disable-next-line prefer-const
  let { enumOptions, enumDisabled } = options;
  if (!enumOptions) {
    enumOptions = [];
  }
  const selectOptions = (enumOptions as { label: string; value: string | number }[])?.map(
    ({ value, ...data }) => ({
      value,
      disabled: (enumDisabled as string[])?.includes(value as string),
      ...data,
    })
  );

  return (
    <FormikSelectField
      name={id}
      label={label || schema.title}
      options={selectOptions}
      multiple={multiple || uiSchema.multiple}
      required={required}
      loading={uiSchema.loading}
    />
  );
};

export default SelectWidget;
