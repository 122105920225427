import { createAction } from 'redux-api-middleware';

export const apiGet = (endpoint: string, type: string, fetch?) =>
  createAction({
    endpoint,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`],
    fetch,
  });

export const apiPost = <TPayload>(endpoint: string, type: string, payload: TPayload, fetch?) =>
  createAction({
    endpoint,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    types: [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`],
    body: JSON.stringify(payload),
    fetch,
  });

export const apiPut = <TPayload>(endpoint: string, type: string, payload: TPayload, fetch?) =>
  createAction({
    endpoint,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`],
    body: JSON.stringify(payload),
    fetch,
  });

export const apiPatch = <TPayload>(endpoint: string, type: string, payload: TPayload, fetch?) =>
  createAction({
    endpoint,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    types: [`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`],
    body: JSON.stringify(payload),
    fetch,
  });
