import { createMainNavigation } from 'modules/Core/services/navigationService';
import { NavigationActionTypes, AppActionTypes } from './actionTypes';
import { UserPermission } from '../types';

export const initialiseMainNavigation = (permissions: UserPermission[]) => ({
  type: NavigationActionTypes.INITIALISE_MAIN_NAVIGATION,
  navigationItems: createMainNavigation(permissions),
});

export const toggleMainNavigationItem = (itemKey: string) => ({
  type: NavigationActionTypes.TOGGLE_MAIN_NAVIGATION_ITEM,
  itemKey,
});

export const toggleSidebarDrawer = () => ({
  type: AppActionTypes.TOGGLE_SIDEBAR_DRAWER,
});
