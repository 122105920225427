import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCardHeader-root': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      '& .MuiTypography-h6': {
        lineHeight: '1.0',
      },
    },

    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },

    '&>.MuiCardContent-root': {
      padding: '0 0 24px 0',
    },
  },
}));

export default useStyles;
