import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './FlexWrapper.styles';

const FlexWrapper = ({ children }) => {
  const classes = makeStyles(styles)();

  return <div className={classes.root}>{children}</div>;
};

FlexWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexWrapper;
