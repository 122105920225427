/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Admin, { AdminPropType } from 'modules/Administration/Container';
import ReportAdminContext from '../ReportAdminContext';
import { useReportAdminContext } from '../useReportAdminContext';

export default ({ children }: Record<string, any>) => {
  const context = useReportAdminContext();
  return (
    <ReportAdminContext.Provider value={context}>
      <ReportAdminContext.Consumer>
        {values => (
          <>
            <Admin {...((values as unknown) as AdminPropType)} />
            {children}
          </>
        )}
      </ReportAdminContext.Consumer>
    </ReportAdminContext.Provider>
  );
};
