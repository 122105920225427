/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';
import { ReportDto } from 'modules/Shared/models';
import { PagedResultDto } from 'modules/Core';

export function useServer() {
  const config = useSelector(getConfig) as AppConfig;
  const apiUrl = config.api.base_uri;

  return React.useMemo(
    () => ({
      getTypes: () => {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
          axios.get<any>(`${apiUrl}/reports/types`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      getById: id => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.get<ReportDto>(`${apiUrl}/reports/${id}`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      getSearch: search => {
        return new Promise<AxiosResponse<PagedResultDto<ReportDto>>>((resolve, reject) => {
          axios
            .get<PagedResultDto<ReportDto>>(`${apiUrl}/reports/search?query=${encodeURI(search)}`)
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        });
      },
      getLatest: () => {
        return new Promise<AxiosResponse<PagedResultDto<ReportDto>>>((resolve, reject) => {
          axios.get<PagedResultDto<ReportDto>>(`${apiUrl}/reports/latest`).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      postReport: data => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.post<ReportDto>(`${apiUrl}/reports`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchReport: (id, data) => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.patch<ReportDto>(`${apiUrl}/reports/${id}`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      deleteReport: id => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios
            .request<ReportDto>({
              url: `${apiUrl}/reports/${id}`,
              method: 'DELETE',
              data: { id },
            })
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        });
      },
      patchReportSettings: (id, data) => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.patch<ReportDto>(`${apiUrl}/reports/${id}/settings`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchPublish: id => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.patch<ReportDto>(`${apiUrl}/reports/${id}/publish`, {}).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchDraft: id => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.patch<ReportDto>(`${apiUrl}/reports/${id}/draft`, {}).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      postPermission: (id, data) => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.post<ReportDto>(`${apiUrl}/reports/${id}/permission`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      patchPermission: (id, data) => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios.patch<ReportDto>(`${apiUrl}/reports/${id}/permission`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        });
      },
      deletePermission: (id, data) => {
        return new Promise<AxiosResponse<ReportDto>>((resolve, reject) => {
          axios
            .delete<ReportDto>(`${apiUrl}/reports/${id}/permission`, { data })
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        });
      },
    }),

    [apiUrl]
  );
}

export default useServer;
