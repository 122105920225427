import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, CssBaseline, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'modules/Common/assets/logo-vertical.png';
import styles from './LoadingScreen.styles';

const LoadingScreen = ({ message = null }) => {
  const classes = makeStyles(styles)();
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img src={logo} alt="LumiraDX" className={classes.logoVertical} />
        <Typography component="h1" variant="h5">
          Data Reporting Platform
        </Typography>
        <div className={classes.progress}>
          <LinearProgress />
        </div>
        <div className={classes.message}>
          {message && <Typography variant="subtitle1">{message}</Typography>}
        </div>
      </Paper>
    </main>
  );
};

LoadingScreen.propTypes = {
  message: PropTypes.string,
};

LoadingScreen.defaultProps = {
  message: null,
};

export default LoadingScreen;
