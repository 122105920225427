/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react/no-unused-state */
/* eslint-disable object-shorthand */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import { Button, Typography } from '@material-ui/core';

import { utils } from '@rjsf/core';

const { dataURItoBlob, shouldRender } = utils;

function addNameToDataURL(dataURL, name) {
  return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
}

function processFile(file) {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = event => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files) {
  return Promise.all([].map.call(files, processFile));
}

function FilesInfo(props) {
  const { filesInfo } = props;
  if (filesInfo.length === 0) {
    return null;
  }
  return (
    <ul className="file-info">
      {filesInfo.map((fileInfo, key) => {
        const { name, size, type } = fileInfo;
        return (
          <li key={key}>
            <strong>{name}</strong> ({type}, {size} bytes)
          </li>
        );
      })}
    </ul>
  );
}

function extractFileInfo(dataURLs) {
  return dataURLs
    .filter(dataURL => typeof dataURL !== 'undefined')
    .map(dataURL => {
      const { blob, name } = dataURItoBlob(dataURL);
      return {
        name: name,
        size: blob.size,
        type: blob.type,
      };
    });
}

class FileWidget extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    const values = Array.isArray(value) ? value : [value];
    this.state = { values, filesInfo: extractFileInfo(values) };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldRender(this, nextProps, nextState);
  }

  onChange = event => {
    const { multiple, onChange } = this.props;

    processFiles(event.target.files).then(filesInfo => {
      const state = {
        values: filesInfo.map(fileInfo => fileInfo.dataURL),
        filesInfo,
      };
      this.setState(state, () => {
        if (multiple) {
          onChange(state.values);
        } else {
          onChange(state.values[0]);
        }
      });
    });
  };

  render() {
    const { multiple, id, readonly, disabled, autofocus, options } = this.props;
    const { filesInfo } = this.state;

    return (
      <div>
        <p>
          <input
            ref={ref => (this.inputRef = ref)}
            id={id}
            type="file"
            onChange={this.onChange}
            defaultValue=""
            disabled={readonly || disabled}
            autoFocus={autofocus}
            multiple={multiple}
            accept={options.accept}
          />
        </p>
        <FilesInfo filesInfo={filesInfo} />
      </div>
    );
  }
}

FileWidget.defaultProps = {
  autofocus: false,
};

if (process.env.NODE_ENV !== 'production') {
  FileWidget.propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    autofocus: PropTypes.bool,
  };
}

export default ({ id, readonly, disabled, autofocus, options }) => {
  const [field, , helper] = useField(id);
  const [, , deletedHelper] = useField(`${id}Deleted`);

  return (
    <label htmlFor={field.name}>
      {field.value?.name && (
        <div>
          <Typography variant="body1">{field.value.name}</Typography>
        </div>
      )}
      {field.value && !field.value.name && (
        <div>
          <Typography variant="body1">
            <Button
              onClick={e => {
                e.preventDefault();
                helper.setValue(undefined);
                deletedHelper.setValue(true);
              }}
            >
              <img
                style={{
                  width: 50,
                  height: 50,
                }}
                alt=""
                src={`/${field.value}`}
              />
            </Button>
          </Typography>
        </div>
      )}

      <input
        type="file"
        name={field.name}
        id={field.name}
        style={{ display: 'none' }}
        disabled={readonly || disabled}
        autoFocus={autofocus}
        accept={options.accept}
        onChange={event => {
          if (!event.target.files) return;
          helper.setValue(event.target.files[0]);
        }}
      />
      <Button color="primary" variant="contained" component="span" disabled={readonly || disabled}>
        Select File
      </Button>
    </label>
  );
};
