import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as pushAction } from 'connected-react-router';
import { CssBaseline, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoVertical from 'modules/Common/assets/logo-vertical.png';
import styles from './SignedOut.styles';

const SignedOut = ({ push }) => {
  const classes = makeStyles(styles)();
  const handleClick = () => push('/auth/login');

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img src={LogoVertical} alt="LumiraDX" className={classes.logoVertical} />
        <Typography component="h1" variant="h5">
          Data Reporting Platform
        </Typography>
        <div className={classes.message}>
          <Typography variant="subtitle1">You have been signed out.</Typography>
          <br />
          <Typography variant="subtitle1">
            <Button
              type="button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleClick}
            >
              Sign in
            </Button>
          </Typography>
        </div>
      </Paper>
    </main>
  );
};

SignedOut.propTypes = {
  push: PropTypes.func.isRequired,
};

export default connect(null, { push: pushAction })(SignedOut);
