/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';

const name = 'autoSave';

export type AutoSaveState = {
  apiActionCount: number;
  syncing: 'active' | 'idle' | 'error' | 'success' | 'paused' | 'init';
  timeout: number;
};

export const initialState: AutoSaveState = {
  apiActionCount: 0,
  syncing: 'init',
  timeout: 1000 * 10,
};

export type RegisterApiCallFunction = () => Promise<any>;

export type RegisterApiCallWithTagsFunction<T = any> = {
  action?: () => Promise<any> | any;
  tags?: string | string[];
  data?: T;
};

export const apiCallRegister = createAction(
  `${name}/apiCallRegister`,
  (id: string, tag: string, func: Promise<unknown>, flush = false) => {
    return {
      payload: {
        id,
        func,
        tag,
        flush,
      },
    };
  }
);

export const flushApiCalls = createAction(
  `${name}/flushApiCalls`,
  (id: string | undefined = undefined, next: any = undefined) => {
    return {
      payload: {
        id,
        next,
      },
    };
  }
);

export type ApiCallSuccessResponse<T = any> = {
  tags: string;
  data: T;
};

export const apiCallSuccess = createAction<ApiCallSuccessResponse>(`${name}/apiCallSuccess`);
export const apiCallError = createAction(`${name}/apiCallError`);

export const autoSaveSlice = createSlice({
  name,
  initialState,
  reducers: {
    apiSyncStarted: state => {
      state.syncing = 'active';
    },
    apiSyncFinished: state => {
      state.syncing = 'success';
    },
    apiSyncError: state => {
      state.syncing = 'error';
    },
    clearSyncSuccess: state => {
      state.syncing = 'idle';
    },
    autoSavePause: state => {
      state.syncing = 'paused';
    },
    autoSaveStart: state => {
      state.syncing = 'idle';
    },
    autoSaveToggle: state => {
      if (state.syncing !== 'paused') {
        state.syncing = 'paused';
      } else {
        state.syncing = 'idle';
      }
    },
  },
});

export const { actions } = autoSaveSlice;

export const {
  apiSyncStarted,
  apiSyncFinished,
  apiSyncError,
  clearSyncSuccess,
  autoSavePause,
  autoSaveStart,
  autoSaveToggle,
} = actions;

export default autoSaveSlice.reducer;

type RootState = {
  autoSave: AutoSaveState;
};

export const selectEditorFulllState = ({ autoSave }: RootState) => autoSave || initialState;

export const selectIsSyncing = createSelector(
  selectEditorFulllState,
  autoSave => autoSave.syncing === 'active'
);
export const selectShowSyncSuccess = createSelector(
  selectEditorFulllState,
  autoSave => autoSave.syncing === 'success'
);
export const selectHasChanges = createSelector(
  selectEditorFulllState,
  autoSave => autoSave.apiActionCount > 0
);
export const selectPaused = createSelector(
  selectEditorFulllState,
  autoSave => autoSave.syncing === 'paused'
);
export const selectTimeout = createSelector(selectEditorFulllState, autoSave => autoSave.timeout);
export const selectShouldSync = createSelector(selectEditorFulllState, autoSave => {
  return autoSave.syncing === 'idle' || autoSave.syncing === 'success';
});

export const selectOutState = createSelector(
  [selectHasChanges, selectShowSyncSuccess, selectIsSyncing, selectPaused, selectTimeout],
  (hasChanges, showSyncSuccess, syncing, paused, timeout) => ({
    hasChanges,
    showSyncSuccess,
    syncing,
    paused,
    timeout,
  })
);
