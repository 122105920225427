import React from 'react';
import { Typography } from '@material-ui/core';
import { WidgetProps, FieldProps } from '@rjsf/core';
import { useField } from 'formik';
import UserSearch from './UserSearch';

export default ({
  registry,
  uiSchema,
  onChange,
  value,
  id,
  name,
  schema,
  label,
  ...props
}: WidgetProps & FieldProps) => {
  const [field, , helpers] = useField(id || name);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleCombo = (_: React.ChangeEvent<{}>, v: unknown) => {
    helpers.setValue(v);
  };

  return (
    <>
      <UserSearch
        {...props}
        id={field.name}
        label={label || schema.title || ''}
        onSelect={handleCombo}
        value={field.value}
      />
      <Typography variant="caption" color="textSecondary">
        {uiSchema.description}
      </Typography>
    </>
  );
};
