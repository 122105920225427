import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#bde3f2',
    textAlign: 'right',
  },
}));

export default useStyles;
