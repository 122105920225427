import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import MomentUtils from '@date-io/moment/';
import { Log } from 'oidc-client';
import { LumiraDxTheme } from '@ldx-dmp/Core';
import { authManager } from '@ldx-dmp/Auth';
import { UserPreferencesProvider } from '@ldx-dmp/User';
import configureAxiosAuth from '@ldx-dmp/Core/http';
import { init as initApm } from '@elastic/apm-rum';

import configureStore, { history } from './configureStore';
import appConfig from './config';
import AppRouter from './routes';

if (appConfig.apm.enabled) {
  initApm(appConfig.apm);
}

if (appConfig.logging.oidc.enabled) {
  Log.logger = console;
  Log.level = appConfig.logging.oidc.level;
}

const store = configureStore(appConfig);

authManager.init(appConfig.oidc);
const userManager = authManager.getUserManager();
// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadUser(store, userManager);

configureAxiosAuth(store);

export default () => {
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={LumiraDxTheme}>
            <UserPreferencesProvider>
              <div className="App">
                <CssBaseline />
                <ConnectedRouter history={history}>
                  <AppRouter />
                </ConnectedRouter>
              </div>
            </UserPreferencesProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </OidcProvider>
    </Provider>
  );
};
