import React from 'react';
import { FormikContext } from 'formik';
import throttle from 'lodash/throttle'; // or whatevs
import isEqual from 'lodash/isEqual';
import AutoSavingSnackbar from './AutoSavingSnackbar';
import AutoSaveSuccessSnackbar from './AutoSaveSuccessSnackbar';
import AutoSaveEnabledSnackbar from './AutoSaveEnabledSnackbar';
import { useAutoSave } from '../useAutoSave';

function AutoSave<T>({
  onSave,
  id,
  useSaga = true,
  timeout = 2000,
  tag,
}: {
  id: string;
  onSave: (data: Partial<T>) => Promise<unknown>;
  timeout?: number;
  useSaga?: boolean;
  tag?: string;
}) {
  const { add, syncing, showSyncSuccess, clear } = useAutoSave();
  const formikContext = React.useContext(FormikContext);

  const [values, setValues] = React.useState();
  const [state, setState] = React.useState<{
    isSaving: boolean;
    saveError?: Error | undefined;
    lastSaved?: Date | undefined;
    success?: boolean;
    init?: boolean;
  }>({
    isSaving: false,
    saveError: undefined,
    lastSaved: undefined,
    success: false,
    init: false,
  });

  const saveLocal = React.useMemo(() => {
    return throttle(
      v => {
        if (!onSave) return;
        setState(s => ({ ...s, isSaving: true, saveError: undefined }));
        onSave(v).then(
          () => setState(s => ({ ...s, isSaving: false, lastSaved: new Date(), success: true })),
          saveError => setState(s => ({ ...s, isSaving: false, saveError }))
        );
      },
      timeout,
      {
        leading: false,
        trailing: true,
      }
    );
  }, [onSave, timeout]);

  const saveSaga = React.useCallback(
    v => {
      add(id, tag, () => {
        return onSave(v);
      });
    },
    [add, id, onSave, tag]
  );

  const save = useSaga ? saveSaga : saveLocal;

  React.useEffect(() => {
    if (!values) {
      setValues(formikContext.values);
      return;
    }

    if (!isEqual(formikContext.values, values)) {
      save(formikContext.values);
      setValues(formikContext.values);
    }
  }, [formikContext.values, save, values]);

  const clearSyncSuccess = React.useCallback(() => {
    setState(s => ({
      ...s,
      success: undefined,
      init: true,
    }));
    clear();
  }, [clear]);

  return (
    <>
      <AutoSavingSnackbar open={syncing} />
      <AutoSaveSuccessSnackbar open={showSyncSuccess} onClose={clearSyncSuccess} />
      <AutoSaveEnabledSnackbar open={!state.init} onClose={clearSyncSuccess} />
    </>
  );
}

export default AutoSave;
