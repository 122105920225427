import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    '& .MuiTab-root': {
      '&.Mui-selected': {
        backgroundColor: 'rgb(28, 184, 220);',
      },

      '&:hover': {
        backgroundColor: 'rgb(28, 184, 220);',
      },
    },
  },
}));
