/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Query, QueryResult } from 'material-table';
import produce from 'immer';
import { buildUrl } from '@ldx-dmp/Core/components/DataTable/dataTableSlice';

export default function useMaterialServer<T extends Record<string, any>>(
  server,
  initialCols,
  updateCol: any = undefined,
  defaultSort = 'lot',
  supportsDelete = true
) {
  const [list, setItems] = React.useState({
    items: [],
    totalCount: undefined,
  });
  const [prevQuery, setQuery] = React.useState<Query<T>>();
  const [state, setState] = React.useState<string>('idle');

  const getDataTable = React.useCallback(
    async function fetchAll(query: Query<T>) {
      const tableState = {
        ...query,
        searchText: '',
        rowsPerPage: query.pageSize,
        page: query.page,
        sortOrder: {
          name: query.orderBy?.field?.toString() || defaultSort,
          direction: query.orderDirection,
        },
      };

      setQuery(query);

      setState(s => (s === 'idle' ? 'loading' : 'fetching'));

      const filters = query.filters.reduce(
        (r, c) => ({
          ...r,
          [`filters[${c.column.field}]`]: c.value,
        }),
        {}
      );

      const url = buildUrl(``, tableState, filters);

      const response = await server.getDataTableAsync(url);
      setItems(response.data);
      setState('loaded');

      return {
        data: response.data.items,
        page: query.page || 0,
        totalCount: response.data.totalCount,
      } as QueryResult<T>;
    },
    [defaultSort, server]
  );

  const fetch = React.useCallback(() => {
    return getDataTable(prevQuery as Query<T>);
  }, [getDataTable, prevQuery]);

  const tableColumns = React.useMemo(() => {
    if (!updateCol) return initialCols;

    return produce(initialCols, cols => {
      return updateCol(
        cols,
        list,
        prevQuery?.filters.reduce(
          (r, c) => ({
            ...r,
            [c.column.field?.toString() || '']: c.value,
          }),
          {}
        ) || {}
      );
    });
  }, [initialCols, list, prevQuery?.filters, updateCol]);

  const editable = React.useMemo(
    () => ({
      isDeletable: () => false,
      isDeleteHidden: () => true,
      onRowUpdate: changes =>
        new Promise((resolve, reject) => {
          server.updateAsync(changes.id, changes).then(() => {
            resolve({});
          }, reject);
        }),
      onBulkUpdate: changes => {
        return new Promise((resolve, reject) => {
          const records = Object.values<{ newData: Record<string, unknown> }>(changes);
          const updates = records.map(x => x.newData);
          server.patchManyAsync(updates).then(() => {
            resolve({});
          }, reject);
        });
      },
    }),
    [server]
  );

  return React.useMemo(
    () => ({
      list,
      columns: tableColumns,
      getDataTable,
      isLoading: state === 'loading',
      isReloading: state === 'fetching',
      options: {
        selection: supportsDelete,
        columnsButton: true,
        search: false,
        actionsColumnIndex: -1,
        emptyRowsWhenPaging: false,
        pageSize: prevQuery?.pageSize || 10,
        pageSizeOptions: [10, 25, 50, 75, 100],
      },
      fetch,
      editable,
    }),
    [list, tableColumns, getDataTable, state, supportsDelete, prevQuery?.pageSize, fetch, editable]
  );
}
