import React from 'react';
import { Field } from 'formik';
import { CircularProgress } from '@material-ui/core';
import SelectField, { SelectFieldProps } from '../SelectField';

export type FormikSelectFieldProps = SelectFieldProps & Record<string, unknown>;

const FormikSelectField = ({ name, loading, ...rest }: FormikSelectFieldProps) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <SelectField
          startAdornment={<>{loading ? <CircularProgress color="inherit" size={15} /> : null}</>}
          name={name}
          {...field}
          value={!field.value ? '' : field.value}
          error={meta.touched && !!meta.error}
          {...rest}
        />
      )}
    </Field>
  );
};

export default FormikSelectField;
