/* eslint-disable import/prefer-default-export */
import { createRef } from 'react';
import Form from '@rjsf/core';
import { JsonForm } from './JsonForm';

export function useJsonForms<T>() {
  return {
    JsonForm,
    formRef: createRef<Form<T>>(),
  };
}
