import React from 'react';
import { Fade, Grid } from '@material-ui/core';
import { useSpeedDial } from '@ldx-drp/SpeedDial';
import { useCategoryActions } from '@ldx-drp/Category/useCategoryActions';
import { ExpandableMenuItem, useNavigation } from '@ldx-drp/Menu';
import { useUserPreferences } from '@ldx-drp/User';
import CategoryReportsList from '@ldx-drp/Category/features/view/containers/CategoryReportsList';
import useFavs from '@ldx-drp/Favourites/useFavs';
import CategoryGridList from './components/CategoryGridList';
import Favourites from './components/Favourites';
import ReportSearch from '../Report/ReportSearch';

export function Home() {
  const {
    state: { categoryContent, recent = [] },
  } = useUserPreferences();
  const { favourites } = useFavs();

  const { menu, move, flat } = useNavigation();
  const { actions } = useCategoryActions(undefined);
  const { setActions, resetActions } = useSpeedDial();

  React.useEffect(() => {
    setActions(actions.filter(x => x.name === 'Add Category'));

    return () => resetActions();
  }, [actions, resetActions, setActions]);

  const reportContent = React.useMemo(
    () => (
      <>
        <Grid item xs>
          <CategoryGridList item={menu} move={move} />
        </Grid>
      </>
    ),
    [menu, move]
  );

  const sideBarContent = React.useMemo(() => {
    if (!favourites?.length && !recent?.length) {
      return <></>;
    }

    return (
      <Grid item xs={3} container spacing={2}>
        {!!favourites?.length && !!flat?.length && (
          <Grid item xs={12}>
            <Favourites
              favourites={
                favourites
                  ?.filter(x => flat?.find(i => i?.elementId === x))
                  ?.map(x => flat?.find(i => i?.elementId === x))
                  ?.map(({ elementId: id, displayName: title, ...rest }) => ({
                    id,
                    title,
                    ...rest,
                  })) || []
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CategoryReportsList
            title="Recently Viewed"
            category={{}}
            reports={[...(recent as ExpandableMenuItem[])]?.splice(0, 5).map(x => ({
              ...x,
              title: x.displayName,
            }))}
          />
        </Grid>
      </Grid>
    );
  }, [favourites, flat, recent]);

  const left = categoryContent === 'left' ? reportContent : sideBarContent;
  const right = categoryContent === 'left' ? sideBarContent : reportContent;

  return (
    <>
      <Fade in>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ReportSearch />
            </Grid>
            {left}
            {right}
          </Grid>
        </>
      </Fade>
    </>
  );
}

export default {};
