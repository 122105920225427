export enum FetchUserInfoActionTypes {
  FETCH_USER_INFO = 'app/FETCH_USER_INFO',
  REQUEST = 'app/FETCH_USER_INFO_REQUEST',
  SUCCESS = 'app/FETCH_USER_INFO_SUCCESS',
  FAILURE = 'app/FETCH_USER_INFO_FAILURE',
}

export enum FetchUserPermissionsActionTypes {
  FETCH_USER_PERMISSIONS = 'app/FETCH_USER_PERMISSIONS',
  REQUEST = 'app/FETCH_USER_PERMISSIONS_REQUEST',
  SUCCESS = 'app/FETCH_USER_PERMISSIONS_SUCCESS',
  FAILURE = 'app/FETCH_USER_PERMISSIONS_FAILURE',
}

export enum NavigationActionTypes {
  INITIALISE_MAIN_NAVIGATION = 'app/INITIALISE_MAIN_NAVIGATION',
  TOGGLE_MAIN_NAVIGATION_ITEM = 'app/TOGGLE_MAIN_NAVIGATION_ITEM',
}

export enum AppActionTypes {
  INITIATE_APP_LOAD = 'app/INITIATE_APP_LOAD',
  APP_LOADED = 'app/APP_LOADED',
  TOGGLE_SIDEBAR_DRAWER = 'app/TOGGLE_SIDEBAR_DRAWER',
}
