/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUserSearch from './useUserSearch';

export default function UserSearch({
  id,
  onSelect,
  value,
  loading,
  label = 'Search using name or email address',
}: any) {
  const { hits, refine } = useUserSearch();
  const [open, setOpen] = React.useState(false);

  return (
    <Autocomplete
      key={id}
      selectOnFocus
      handleHomeEndKeys
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, newValue: any) => {
        if (typeof newValue === 'string' && value?.name !== newValue) {
          onSelect(event, {
            name: newValue,
            identifier: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onSelect(event, {
            name: newValue.inputValue,
            identifier: newValue.identifier || newValue.inputValue,
          });
        } else {
          onSelect(event, newValue);
        }
      }}
      value={value}
      getOptionSelected={(option, v) => {
        return option.name === v.name;
      }}
      filterOptions={a => a}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      options={hits}
      loading={loading}
      onInputChange={(e: any) => {
        return refine(e?.target?.value || '');
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
