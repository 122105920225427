import React from 'react';
import PropTypes from 'prop-types';
import { Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoFlameWhite from 'modules/Common/assets/logo-flame-white.png';
import styles from './MiniLogo.styles';

const MiniLogo = ({ isSidebarOpen, alt = 'Data Reporting Platform' }) => {
  const classes = makeStyles(styles)({ isSidebarOpen });
  return (
    <Zoom timeout={750} in={!isSidebarOpen} out={isSidebarOpen}>
      <div className={classes.toolbarLogo}>
        <img src={LogoFlameWhite} className={classes.toolbarLogoImage} title={alt} alt={alt} />
      </div>
    </Zoom>
  );
};

MiniLogo.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
  alt: PropTypes.string,
};

export default MiniLogo;
