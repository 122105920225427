export const rootPath = '/report-types';
export const entityPath = '/report-types/:id';

export const addTitle = 'Not Supported';
export const editTitle = 'Editing Report Type';
export const listTitle = 'Report Types';
export const addButtonText = 'Not Supported';
export const i18Namespace = 'ReportTypes';
export const defaultSort = 'title';
export const supportsDelete = false;
