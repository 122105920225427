/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './reportInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      title: {
        type: 'string',
        title: 'Title',
      },
      categories: {
        title: 'Categories',
        type: 'array',
        items: {
          type: 'string',
          title: 'Category',
        },
      },
      description: {
        type: 'string',
        title: 'Description',
      },
      department: {
        type: 'string',
        title: 'Department',
      },
      owner: {
        title: 'Owner',
        type: 'object',
        properties: {
          identifier: {
            type: 'string',
            title: 'Identifier',
          },
          name: {
            type: 'string',
            title: 'Name',
          },
        },
      },
      ownerDepartment: {
        type: 'string',
        title: 'Owner Department',
      },
      reportType: {
        type: 'string',
        title: 'Report Type',
      },
      status: {
        title: 'Status',
        type: 'string',
        enum: ['Draft', 'Published'],
        enumNames: ['Draft', 'Published'],
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  title: '',
  categories: [],
  description: '',
  department: '',
  owner: {},
  ownerDepartment: '',
  reportType: '',
  status: '',
});
