import React from 'react';
import { Divider, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WhiteLogo from 'modules/Common/assets/logo-white.png';
import styles from './Logo.styles';

const Logo = () => {
  const classes = makeStyles(styles)();
  return (
    <>
      <div className={classes.root}>
        <ListItem>
          <img src={WhiteLogo} className={classes.logoImg} alt="LumiraDX" />
        </ListItem>
      </div>
      <Divider />
    </>
  );
};

export default Logo;
