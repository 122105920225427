import { apiGet } from './apiActions';

export enum FetchAbpApplicationConfig {
  CALL = 'app/FETCH_ABP_APPLICATION_CONFIG',
  REQUEST = 'app/FETCH_ABP_APPLICATION_CONFIG_REQUEST',
  SUCCESS = 'app/FETCH_ABP_APPLICATION_CONFIG_SUCCESS',
  FAILURE = 'app/FETCH_ABP_APPLICATION_CONFIG_FAILURE',
}

export const fetchAbpApplicationConfig = () =>
  apiGet('/api/abp/application-configuration', FetchAbpApplicationConfig.CALL);
